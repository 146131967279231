import React, { useState, useCallback } from "react";
import {
  Box,
  Container,
  Typography,
  Paper,
  TextField,
  Grid,
  Button,
  Divider,
  useTheme,
  useMediaQuery,
  Autocomplete,
  Switch,
  Stack,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";
import { useLoadScript } from "@react-google-maps/api";
import CalculateIcon from "@mui/icons-material/Calculate";
import RevenueSeasonality from "./RevenueSeasonality";
import PropertyComparables from "./PropertyComparables";
import InvestmentProjection from "./InvestmentProjection";
import FinancialDetails from "./FinancialDetails";
import HeroSection from "./HeroSection";
import PropertyOverview from "./PropertyOverview";
import MetricCard from "./MetricCard";

const libraries = ["places"];

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#e0e0e0",
    },
    "&:hover fieldset": {
      borderColor: theme.palette.primary.main,
    },
  },
}));

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#e0e0e0",
    },
    "&:hover fieldset": {
      borderColor: theme.palette.primary.main,
    },
  },
}));

const AirbnbCalculator = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [showFinancialDetails, setShowFinancialDetails] = useState(false);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyD-KhOCtJj26j22htiFwyNwTZe4SPdALk0",
    libraries,
  });

  const [searchInput, setSearchInput] = useState("");
  const [predictions, setPredictions] = useState([]);

  const [propertyDetails, setPropertyDetails] = useState({
    address: "",
    placeId: "",
    lat: null,
    lng: null,
    bedrooms: "",
    bathrooms: "",
    guests: "",
    sqft: "",
    propertyType: "",
  });

  const [calculatedMetrics, setCalculatedMetrics] = useState({
    score: 417,
    annualProfit: 141326,
    capRate: 26.82,
    cashOnCash: 81.0,
    estimatedRevenue: 19400,
    occupancyRate: 59,
    averageDailyRate: 1080,
    monthlyMortgage: 3956,
    cashFlow: 11777,
    roi: 97.05,
  });

  // Add new state for shared components
  const [sliderOccupancyRate, setSliderOccupancyRate] = useState(80);
  const [avgDailyRate, setAvgDailyRate] = useState(347);
  const [occupancyPercentile, setOccupancyPercentile] = useState(46);
  const [dailyRatePercentile, setDailyRatePercentile] = useState(44);
  const [isCustomized, setIsCustomized] = useState(false);

  // Mock ML prediction data - TODO: Replace with actual ML service call
  const [mlPredictions, setMlPredictions] = useState({
    predictedRevenue: 101392,
    predictedOccupancy: 78,
    predictedDailyRate: 351,
    revenuePercentile: 48,
  });

  const [financialDetails, setFinancialDetails] = useState({
    purchasePrice: "786196",
    downPayment: "20",
    interestRate: "6.5",
    rentalRevenue: "33890",
    managementFee: "20",
    managementFeeAmount: 5679,
    lodgingTax: "5",
    lodgingTaxAmount: 1695,
    propertyTax: "1",
    propertyTaxAmount: 7862,
    cleaningFees: "5493",
    maintenance: "0",
    insurance: "0",
    utilities: "0",
    hoaFees: "0",
    otherOperating: "0",
    homeFurnishings: "0",
    homeImprovement: "0",
    closingCosts: "0",
    otherStartup: "0",
    isCashOnly: false,
    useLoan: true,
  });

  // Sample data for charts and tables
  const seasonalityData = [
    { month: "Jan", revenue: 7800, profit: 5000 },
    { month: "Feb", revenue: 7900, profit: 5100 },
    { month: "Mar", revenue: 10000, profit: 6500 },
    { month: "Apr", revenue: 12200, profit: 8000 },
    { month: "May", revenue: 12700, profit: 8300 },
    { month: "Jun", revenue: 15000, profit: 10000 },
    { month: "Jul", revenue: 16500, profit: 11000 },
    { month: "Aug", revenue: 14400, profit: 9500 },
    { month: "Sep", revenue: 9400, profit: 6200 },
    { month: "Oct", revenue: 11800, profit: 7700 },
    { month: "Nov", revenue: 9200, profit: 6000 },
    { month: "Dec", revenue: 13400, profit: 8800 },
  ];

  const comparablesData = [
    {
      id: "1",
      name: "Quaint 4 Bedroom Ranch Home",
      url: "#",
      revenue: 67407,
      perNight: 308,
      occupancy: 58,
      beds: 4,
      baths: 2,
    },
    {
      id: "2",
      name: "Cheerful 4 bedroom home",
      url: "#",
      revenue: 82387,
      perNight: 326,
      occupancy: 68,
      beds: 4,
      baths: 4,
    },
    {
      id: "3",
      name: "Welcoming & Cozy, Near Kings Island",
      url: "#",
      revenue: 84770,
      perNight: 291,
      occupancy: 77,
      beds: 4,
      baths: 2,
    },
    {
      id: "4",
      name: "Lovely Retreat Backyard",
      url: "#",
      revenue: 20642,
      perNight: 132,
      occupancy: 39,
      beds: 4,
      baths: 2,
    },
    {
      id: "5",
      name: "Liberty House Near Kings Island",
      url: "#",
      revenue: 63137,
      perNight: 284,
      occupancy: 57,
      beds: 4,
      baths: 2,
    },
  ];

  const projectionData = [
    {
      year: 1,
      profit: 141326,
      propertyAppreciation: 35000,
      principalPaydown: 12000,
      downPayment: 140760,
    },
    {
      year: 2,
      profit: 148392,
      propertyAppreciation: 52500,
      principalPaydown: 25000,
      downPayment: 140760,
    },
    {
      year: 3,
      profit: 155458,
      propertyAppreciation: 70000,
      principalPaydown: 38000,
      downPayment: 140760,
    },
    {
      year: 4,
      profit: 163231,
      propertyAppreciation: 87500,
      principalPaydown: 52000,
      downPayment: 140760,
    },
    {
      year: 5,
      profit: 171004,
      propertyAppreciation: 105000,
      principalPaydown: 66000,
      downPayment: 140760,
    },
    {
      year: 6,
      profit: 175554,
      propertyAppreciation: 132000,
      principalPaydown: 85000,
      downPayment: 140760,
    },
    {
      year: 7,
      profit: 179554,
      propertyAppreciation: 157000,
      principalPaydown: 102000,
      downPayment: 140760,
    },
    {
      year: 8,
      profit: 182404,
      propertyAppreciation: 175000,
      principalPaydown: 118000,
      downPayment: 140760,
    },
    {
      year: 9,
      profit: 185254,
      propertyAppreciation: 192000,
      principalPaydown: 130000,
      downPayment: 140760,
    },
    {
      year: 10,
      profit: 188104,
      propertyAppreciation: 210000,
      principalPaydown: 140760,
      downPayment: 140760,
    },
  ];

  const handlePlaceSelect = useCallback(async (placeId) => {
    if (!placeId) return;

    const geocoder = new window.google.maps.Geocoder();
    try {
      const result = await new Promise((resolve, reject) => {
        geocoder.geocode({ placeId }, (results, status) => {
          if (status === "OK") {
            resolve(results[0]);
          } else {
            reject(new Error("Geocoder failed"));
          }
        });
      });

      const { lat, lng } = result.geometry.location;
      setPropertyDetails((prev) => ({
        ...prev,
        address: result.formatted_address,
        placeId,
        lat: lat(),
        lng: lng(),
      }));
    } catch (error) {
      console.error("Error fetching place details:", error);
    }
  }, []);

  const handleAddressSearch = useCallback(
    async (input) => {
      if (!input || !isLoaded) return;

      const service = new window.google.maps.places.AutocompleteService();
      try {
        const results = await new Promise((resolve, reject) => {
          service.getPlacePredictions(
            {
              input,
              types: ["address"],
              componentRestrictions: { country: "us" },
            },
            (predictionResults, status) => {
              if (status === "OK") {
                resolve(predictionResults);
              } else {
                reject(new Error("Autocomplete service failed"));
              }
            }
          );
        });
        setPredictions(results || []);
      } catch (error) {
        console.error("Error fetching predictions:", error);
        setPredictions([]);
      }
    },
    [isLoaded]
  );

  const handleInputChange = (field) => (event) => {
    setPropertyDetails({
      ...propertyDetails,
      [field]: event.target.value,
    });
  };

  const calculateMortgage = (
    principal,
    downPaymentPercent,
    interestRate,
    years = 30
  ) => {
    const downPayment = (principal * downPaymentPercent) / 100;
    const loanAmount = principal - downPayment;
    const monthlyRate = interestRate / 100 / 12;
    const numberOfPayments = years * 12;

    const monthlyPayment =
      (loanAmount * (monthlyRate * (1 + monthlyRate) ** numberOfPayments)) /
      ((1 + monthlyRate) ** numberOfPayments - 1);

    return monthlyPayment;
  };

  const estimateRevenue = (bedrooms, bathrooms, location) => {
    const baseRatePerBedroom = 100;
    const baseOccupancyRate = 0.7;

    const getLocationMultiplier = () => {
      const defaultMultiplier = 1.0;
      if (!location.lat || !location.lng) return defaultMultiplier;

      const isCoastal = Math.abs(location.lng) > 115;
      const isMajorCity = true;

      let multiplier = defaultMultiplier;
      if (isCoastal) multiplier *= 1.2;
      if (isMajorCity) multiplier *= 1.3;

      return multiplier;
    };

    const locationMultiplier = getLocationMultiplier();
    const bathroomMultiplier = 1 + bathrooms * 0.1;

    const dailyRate =
      baseRatePerBedroom * bedrooms * locationMultiplier * bathroomMultiplier;

    const daysInMonth = 30;
    const occupancyRate = baseOccupancyRate * locationMultiplier;
    const monthlyRevenue = dailyRate * daysInMonth * occupancyRate;

    return {
      monthlyRevenue: Math.round(monthlyRevenue),
      dailyRate: Math.round(dailyRate),
      occupancyRate: Math.round(occupancyRate * 100),
    };
  };

  const calculateOperatingCosts = (purchasePrice) => {
    const annualPropertyTax = purchasePrice * 0.01;
    const annualInsurance = purchasePrice * 0.005;
    const annualMaintenance = purchasePrice * 0.01;
    const monthlyUtilities = 200;
    const monthlyCleaningPerBooking = 100;
    const monthlyManagement = purchasePrice * 0.001;

    return {
      propertyTax: annualPropertyTax / 12,
      insurance: annualInsurance / 12,
      maintenance: annualMaintenance / 12,
      utilities: monthlyUtilities,
      cleaning: monthlyCleaningPerBooking * 15,
      management: monthlyManagement,
    };
  };

  const handleCalculate = () => {
    const {
      purchasePrice,
      downPayment,
      interestRate,
      bedrooms,
      bathrooms,
      lat,
      lng,
    } = propertyDetails;

    if (
      !purchasePrice ||
      !downPayment ||
      !interestRate ||
      !bedrooms ||
      !bathrooms
    ) {
      alert("Please fill in all required fields");
      return;
    }

    const monthlyMortgage = calculateMortgage(
      parseFloat(purchasePrice),
      parseFloat(downPayment),
      parseFloat(interestRate)
    );

    const revenueEstimates = estimateRevenue(
      parseFloat(bedrooms),
      parseFloat(bathrooms),
      { lat, lng }
    );

    const operatingCosts = calculateOperatingCosts(parseFloat(purchasePrice));

    const totalMonthlyExpenses =
      monthlyMortgage +
      operatingCosts.propertyTax +
      operatingCosts.insurance +
      operatingCosts.maintenance +
      operatingCosts.utilities +
      operatingCosts.cleaning +
      operatingCosts.management;

    const monthlyCashFlow =
      revenueEstimates.monthlyRevenue - totalMonthlyExpenses;

    const downPaymentAmount =
      (parseFloat(purchasePrice) * parseFloat(downPayment)) / 100;
    const annualCashFlow = monthlyCashFlow * 12;
    const roi = (annualCashFlow / downPaymentAmount) * 100;

    setCalculatedMetrics({
      estimatedRevenue: revenueEstimates.monthlyRevenue,
      occupancyRate: revenueEstimates.occupancyRate,
      averageDailyRate: revenueEstimates.dailyRate,
      monthlyMortgage: Math.round(monthlyMortgage),
      cashFlow: Math.round(monthlyCashFlow),
      roi: Math.round(roi * 10) / 10,
    });
  };

  // Handler for occupancy rate changes
  const handleOccupancyChange = (event, newValue) => {
    setSliderOccupancyRate(newValue);
    // Only set to customized if the value is different from predicted
    if (newValue !== mlPredictions.predictedOccupancy) {
      setIsCustomized(true);
    }
    // TODO: Call ML service to update predictions based on new occupancy
    // For now, we'll just update the percentile mock data
    setOccupancyPercentile(Math.round(100 - (newValue / 100) * 100));
  };

  // Handler for daily rate changes
  const handleDailyRateChange = (event, newValue) => {
    setAvgDailyRate(newValue);
    // Only set to customized if the value is different from predicted
    if (newValue !== mlPredictions.predictedDailyRate) {
      setIsCustomized(true);
    }
    // TODO: Call ML service to update predictions based on new daily rate
    // For now, we'll just update the percentile mock data
    setDailyRatePercentile(Math.round(100 - (newValue / 1000) * 100));
  };

  if (loadError) {
    return (
      <Typography color="error" sx={{ p: 2 }}>
        Error loading Google Maps API. Please try again later.
      </Typography>
    );
  }

  return (
    <Box
      sx={{
        backgroundColor: "transparent",
        background:
          "linear-gradient(180deg, rgb(235, 245, 255) 0%, rgba(250, 250, 250, 0.8) 25%, rgba(250, 250, 250, 0.9) 50%, rgba(255, 255, 255, 1) 100%)",
      }}
    >
      <HeroSection />

      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Grid container spacing={4}>
          {/* Property Overview */}
          <Grid item xs={12}>
            <PropertyOverview
              isCustomized={isCustomized}
              propertyDetails={propertyDetails}
              onInputChange={handleInputChange}
              predictions={predictions}
              searchInput={searchInput}
              handlePlaceSelect={handlePlaceSelect}
              handleAddressSearch={handleAddressSearch}
              isLoaded={isLoaded}
              calculatedMetrics={calculatedMetrics}
            />
          </Grid>

          {/* Seasonality Chart */}
          <Grid item xs={12}>
            <RevenueSeasonality
              data={seasonalityData}
              occupancyRate={sliderOccupancyRate}
              avgDailyRate={avgDailyRate}
              onOccupancyChange={handleOccupancyChange}
              onDailyRateChange={handleDailyRateChange}
              occupancyPercentile={occupancyPercentile}
              dailyRatePercentile={dailyRatePercentile}
              predictedRevenue={mlPredictions.predictedRevenue}
              predictedOccupancy={mlPredictions.predictedOccupancy}
              predictedDailyRate={mlPredictions.predictedDailyRate}
              revenuePercentile={mlPredictions.revenuePercentile}
              setIsCustomized={setIsCustomized}
            />
          </Grid>

          {/* Financial Details & Key Performance Metrics Combined */}
          <Grid item xs={12}>
            <Paper
              sx={{
                backgroundColor: "rgba(255, 255, 255, 0.8)",
                backdropFilter: "blur(10px)",
                p: 3,
              }}
            >
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  mb: 3,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                Financials
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() =>
                    setFinancialDetails({
                      purchasePrice: "786196",
                      downPayment: "20",
                      interestRate: "6.5",
                      rentalRevenue: "33890",
                      managementFee: "20",
                      managementFeeAmount: 5679,
                      lodgingTax: "5",
                      lodgingTaxAmount: 1695,
                      propertyTax: "1",
                      propertyTaxAmount: 7862,
                      cleaningFees: "5493",
                      maintenance: "0",
                      insurance: "0",
                      utilities: "0",
                      hoaFees: "0",
                      otherOperating: "0",
                      homeFurnishings: "0",
                      homeImprovement: "0",
                      closingCosts: "0",
                      otherStartup: "0",
                      isCashOnly: false,
                      useLoan: true,
                    })
                  }
                  sx={{
                    textTransform: "none",
                    minWidth: "unset",
                    px: 2,
                  }}
                >
                  Reset
                </Button>
              </Typography>

              {/* Performance Metrics Cards at the top */}
              <Grid container spacing={3} sx={{ mb: 4 }}>
                <Grid item xs={12} sm={4}>
                  <MetricCard
                    title="Annual Profit"
                    value={
                      <NumberFormat
                        value={calculatedMetrics.annualProfit || 0}
                        displayType="text"
                        thousandSeparator
                        prefix="$"
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <MetricCard
                    title="Cap Rate"
                    value={`${calculatedMetrics.capRate || 0}%`}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <MetricCard
                    title="Cash on Cash"
                    value={`${calculatedMetrics.cashOnCash || 0}%`}
                  />
                </Grid>
              </Grid>

              {/* Financial Details taking full width */}
              <Box>
                <FinancialDetails
                  financialDetails={financialDetails}
                  onFinancialDetailsChange={setFinancialDetails}
                  disableCard
                  sx={{
                    "& .MuiAccordion-root": {
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.02)",
                        cursor: "pointer",
                      },
                      "& .MuiAccordionSummary-root": {
                        "&:hover": {
                          backgroundColor: "rgba(0, 0, 0, 0.04)",
                        },
                        "& .MuiAccordionSummary-expandIconWrapper": {
                          color: "primary.main",
                          transition: "all 0.2s ease",
                          "&:hover": {
                            transform: "scale(1.1)",
                          },
                        },
                      },
                      "& .MuiAccordionSummary-content": {
                        "& .MuiTypography-root": {
                          color: "primary.main",
                          fontWeight: 500,
                        },
                      },
                    },
                  }}
                />
              </Box>
            </Paper>
          </Grid>

          {/* Property Comparables */}
          <Grid item xs={12}>
            <PropertyComparables
              comparables={comparablesData}
              occupancyRate={sliderOccupancyRate}
              avgDailyRate={avgDailyRate}
            />
          </Grid>

          {/* Investment Projection */}
          <Grid item xs={12}>
            <InvestmentProjection data={projectionData} />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default AirbnbCalculator;
