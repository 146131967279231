import React, { useState, useRef, useEffect, useMemo } from "react";
import {
  GoogleMap,
  OverlayView,
  OverlayViewF,
  useLoadScript,
} from "@react-google-maps/api";
import {
  Box,
  Paper,
  Typography,
  Slider,
  Stack,
  CircularProgress,
  Divider,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { fi } from "date-fns/locale";
import AirbnbListingCard from "../AirbnbListingCard";
import { mapStyles } from "../airbnbMapUtil";

// SF neighborhoods coordinates for more realistic distribution
const SF_NEIGHBORHOODS = [
  { name: "Mission District", lat: 37.7599, lng: -122.4148 },
  { name: "North Beach", lat: 37.7997, lng: -122.4098 },
  { name: "Hayes Valley", lat: 37.7759, lng: -122.4245 },
  { name: "Marina", lat: 37.8015, lng: -122.4368 },
  { name: "Castro", lat: 37.7609, lng: -122.435 },
  { name: "Nob Hill", lat: 37.793, lng: -122.4161 },
  { name: "SoMa", lat: 37.7785, lng: -122.4056 },
  { name: "Pacific Heights", lat: 37.7925, lng: -122.4382 },
];

// Helper to get random location near a neighborhood
const getRandomLocation = (base, radius = 0.01) => ({
  lat: base.lat + (Math.random() - 0.5) * radius,
  lng: base.lng + (Math.random() - 0.5) * radius,
});

// Mock data for 40 listings in San Francisco Bay Area
const MOCK_LISTINGS = Array.from({ length: 40 }, (_, i) => {
  const neighborhood = SF_NEIGHBORHOODS[i % SF_NEIGHBORHOODS.length];
  const location = getRandomLocation(neighborhood);
  const beds = 2 + Math.floor(Math.random() * 3);
  const occupancy = 60 + Math.random() * 40;
  const adr = 200 + Math.random() * 300;

  return {
    id: `listing-${i + 1}`,
    name: `${neighborhood.name} ${beds}BR Modern Home`,
    revenue: Math.round(adr * 365 * (occupancy / 100)),
    perNight: Math.round(adr),
    occupancy: Math.round(occupancy),
    beds,
    baths: 1 + Math.floor(Math.random() * beds),
    guests: beds * 2,
    latitude: location.lat,
    longitude: location.lng,
    photos: ["https://picsum.photos/400/300"], // Mock photo
    rating: 4 + Math.random(),
    reviewCount: Math.floor(10 + Math.random() * 90),
    neighborhood: neighborhood.name,
  };
});

// Sort listings by revenue from low to high for the bar chart
const SORTED_LISTINGS = [...MOCK_LISTINGS].sort(
  (a, b) => a.revenue - b.revenue
);

// Helper function to generate nice round numbers for ticks
const generateNiceTicks = (min, max, tickCount = 5) => {
  const range = max - min;
  const roughStep = range / (tickCount - 1);
  const magnitude = 10 ** Math.floor(Math.log10(roughStep));
  const normalizedStep = Math.round(roughStep / magnitude);
  const niceStep = normalizedStep * magnitude;

  const niceMin = Math.floor(min / niceStep) * niceStep;
  const niceMax = Math.ceil(max / niceStep) * niceStep;

  const ticks = [];
  for (let i = 0; i < tickCount; i++) {
    const value = niceMin + i * niceStep;
    if (value <= niceMax) {
      ticks.push(value);
    }
  }

  // Ensure first and last ticks match actual min/max
  ticks[0] = min;
  ticks[ticks.length - 1] = max;

  return ticks;
};

// Get min and max revenue for slider and ticks
const MIN_REVENUE = Math.floor(
  Math.min(...MOCK_LISTINGS.map((l) => l.revenue))
);
const MAX_REVENUE = Math.ceil(Math.max(...MOCK_LISTINGS.map((l) => l.revenue)));

// Generate tick values for x-axis
const TICK_VALUES = generateNiceTicks(MIN_REVENUE, MAX_REVENUE);

const formatRevenue = (value) => {
  return `$${Math.round(value / 1000)}k`;
};

// Custom marker icon path for Airbnb-style pins
const MARKER_PATH =
  "M-1.547 12l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM0 0q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z";

const libraries = ["places"];

const PropertyComparables = () => {
  const theme = useTheme();
  const mapRef = useRef(null);
  const [selectedListing, setSelectedListing] = useState(null);
  const [hoveredListing, setHoveredListing] = useState(null);
  const [revenueRange, setRevenueRange] = useState([MIN_REVENUE, MAX_REVENUE]);
  const [zoomLevel, setZoomLevel] = useState(13);
  const [showAllListings, setShowAllListings] = useState(false);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyD-KhOCtJj26j22htiFwyNwTZe4SPdALk0",
    libraries,
  });

  const center = useMemo(
    () => ({
      lat: 37.7749,
      lng: -122.4194,
    }),
    []
  );

  // Filter listings based on revenue range
  const filteredListings = useMemo(() => {
    return MOCK_LISTINGS.filter(
      (listing) =>
        listing.revenue >= revenueRange[0] && listing.revenue <= revenueRange[1]
    );
  }, [revenueRange]);

  const handleMapLoad = (mapInstance) => {
    mapRef.current = mapInstance;
    setZoomLevel(mapInstance.getZoom());

    mapInstance.addListener("zoom_changed", () => {
      setZoomLevel(mapInstance.getZoom());
    });
  };

  const handleRevenueRangeChange = (event, newValue) => {
    setRevenueRange(newValue);
  };

  const renderMarker = (listing) => {
    const isActive =
      selectedListing?.id === listing.id || hoveredListing?.id === listing.id;
    console.log(listing);

    return (
      <OverlayViewF
        key={listing.id}
        position={{ lat: listing.latitude, lng: listing.longitude }}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      >
        <Box
          onClick={() => setSelectedListing(listing)}
          onMouseEnter={() => setHoveredListing(listing)}
          onMouseLeave={() => setHoveredListing(null)}
          sx={{
            width: "24px",
            height: "24px",
            backgroundColor: isActive ? theme.palette.primary.main : "#FF5A5F",
            borderRadius: "50%",
            transform: "translate(-50%, -50%)",
            cursor: "pointer",
            border: "2px solid white",
            boxShadow: "0 2px 4px rgba(0,0,0,0.18)",
            transition: "transform 0.2s ease-in-out",
            "&:hover": {
              transform: "translate(-50%, -50%) scale(1.1)",
            },
          }}
        />
      </OverlayViewF>
    );
  };

  if (loadError) {
    return (
      <Paper sx={{ p: 3, width: "100%" }}>
        <Typography color="error">
          Error loading Google Maps. Please try again later.
        </Typography>
      </Paper>
    );
  }

  if (!isLoaded) {
    return (
      <Paper
        sx={{
          p: 3,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: 400,
        }}
      >
        <CircularProgress />
      </Paper>
    );
  }

  return (
    <Stack spacing={4}>
      <Paper sx={{ p: 3, width: "100%" }}>
        <Typography variant="h6" gutterBottom>
          Airbnb Comparables
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
          Identify properties similar to yours in terms of amenities and
          interior design. Then adjust your Occupancy Rate and Average Daily
          Rate to match.
        </Typography>

        {/* Revenue Range Slider */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            Revenue per year
          </Typography>
          <Box sx={{ px: 1 }}>
            <Slider
              value={revenueRange}
              onChange={handleRevenueRangeChange}
              valueLabelDisplay="auto"
              valueLabelFormat={formatRevenue}
              min={MIN_REVENUE}
              max={MAX_REVENUE}
              sx={{
                "& .MuiSlider-thumb": {
                  height: 16,
                  width: 16,
                  border: "2px solid currentColor",
                  "&:hover": {
                    boxShadow: "0 0 0 8px rgba(58, 133, 137, 0.16)",
                  },
                },
                "& .MuiSlider-track": {
                  height: 4,
                },
                "& .MuiSlider-rail": {
                  height: 4,
                  opacity: 0.5,
                  backgroundColor: "#bfbfbf",
                },
                "& .MuiSlider-mark": {
                  backgroundColor: "#bfbfbf",
                  height: 8,
                  width: 1,
                  "&.MuiSlider-markActive": {
                    opacity: 1,
                    backgroundColor: "currentColor",
                  },
                },
              }}
            />
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}
            >
              <Typography variant="caption" color="text.secondary">
                {formatRevenue(MIN_REVENUE)}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                {formatRevenue(MAX_REVENUE)}
              </Typography>
            </Box>
          </Box>
        </Box>

        {/* Revenue Bar Chart */}
        <Box
          sx={{
            height: "140px", // Increased height to accommodate x-axis
            position: "relative",
            mt: 4,
          }}
        >
          {/* X-axis ticks */}
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              height: "40px",
              borderTop: "1px solid #e0e0e0",
              display: "flex",
            }}
          >
            {TICK_VALUES.map((value, index) => {
              const position = `${
                ((value - MIN_REVENUE) / (MAX_REVENUE - MIN_REVENUE)) * 100
              }%`;
              const isFirst = index === 0;
              const isLast = index === TICK_VALUES.length - 1;

              let transformValue = "translateX(-50%)";
              if (isFirst) transformValue = "translateX(0)";
              if (isLast) transformValue = "translateX(-100%)";

              return (
                <Box
                  key={value}
                  sx={{
                    position: "absolute",
                    left: position,
                    top: 0,
                    height: "8px",
                    width: "1px",
                    backgroundColor: "#e0e0e0",
                    transform: "translateX(-50%)",
                  }}
                >
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    sx={{
                      position: "absolute",
                      top: 16,
                      left: "50%",
                      transform: transformValue,
                      whiteSpace: "nowrap",
                    }}
                  >
                    {formatRevenue(value)}
                  </Typography>
                </Box>
              );
            })}
          </Box>

          {/* Bars */}
          <Box
            sx={{
              height: "100px",
              display: "flex",
              alignItems: "flex-end",
              gap: "2px",
            }}
          >
            {SORTED_LISTINGS.map((listing) => (
              <Box
                key={listing.id}
                sx={{
                  flex: 1,
                  height: "100%",
                  position: "relative",
                  cursor: "pointer",
                  opacity:
                    listing.revenue >= revenueRange[0] &&
                    listing.revenue <= revenueRange[1]
                      ? 1
                      : 0.3,
                  "&:hover": {
                    "& > div": {
                      backgroundColor: theme.palette.primary.main,
                    },
                  },
                }}
                onMouseEnter={() => setHoveredListing(listing)}
                onMouseLeave={() => setHoveredListing(null)}
              >
                <Box
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    backgroundColor:
                      hoveredListing?.id === listing.id
                        ? theme.palette.primary.main
                        : "#000",
                    height: `${(listing.revenue / MAX_REVENUE) * 100}%`,
                    transition: "background-color 0.2s",
                  }}
                />
              </Box>
            ))}
          </Box>
        </Box>

        {/* Google Map with Airbnb Listings */}
        <Box
          sx={{
            height: "400px",
            width: "100%",
            position: "relative",
            borderRadius: "4px",
            overflow: "hidden",
            mt: 3,
          }}
        >
          <GoogleMap
            id="google-map-comparables"
            mapContainerStyle={{
              height: "100%",
              width: "100%",
            }}
            zoom={zoomLevel}
            center={center}
            onLoad={handleMapLoad}
            options={{
              styles: mapStyles,
              gestureHandling: "greedy",
              mapTypeControl: false,
              fullscreenControl: false,
              streetViewControl: false,
              zoomControl: true,
              clickableIcons: false,
            }}
          >
            {filteredListings.map(renderMarker)}
          </GoogleMap>

          {/* Listing Card Overlay */}
          {(selectedListing || hoveredListing) && (
            <Box
              sx={{
                position: "absolute",
                bottom: 16,
                left: 16,
                zIndex: 1,
              }}
            >
              <AirbnbListingCard listing={selectedListing || hoveredListing} />
            </Box>
          )}
        </Box>

        {/* Listings Table */}
        <Box sx={{ mt: 4, px: 3 }}>
          <Box sx={{ overflowX: "auto" }}>
            <Box
              sx={{
                display: "table",
                minWidth: "100%",
                borderCollapse: "collapse",
              }}
            >
              {/* Table Header */}
              <Box sx={{ display: "table-header-group" }}>
                <Box sx={{ display: "table-row" }}>
                  <Box
                    sx={{
                      display: "table-cell",
                      py: 2,
                      fontWeight: "bold",
                      borderBottom: "1px solid",
                      borderColor: "divider",
                    }}
                  >
                    Airbnb Name
                  </Box>
                  <Box
                    sx={{
                      display: "table-cell",
                      py: 2,
                      fontWeight: "bold",
                      borderBottom: "1px solid",
                      borderColor: "divider",
                      textAlign: "right",
                    }}
                  >
                    Revenue
                  </Box>
                  <Box
                    sx={{
                      display: "table-cell",
                      py: 2,
                      fontWeight: "bold",
                      borderBottom: "1px solid",
                      borderColor: "divider",
                      textAlign: "right",
                    }}
                  >
                    Per Night
                  </Box>
                  <Box
                    sx={{
                      display: "table-cell",
                      py: 2,
                      fontWeight: "bold",
                      borderBottom: "1px solid",
                      borderColor: "divider",
                      textAlign: "right",
                    }}
                  >
                    Occupancy
                  </Box>
                  <Box
                    sx={{
                      display: "table-cell",
                      py: 2,
                      fontWeight: "bold",
                      borderBottom: "1px solid",
                      borderColor: "divider",
                      textAlign: "right",
                    }}
                  >
                    Beds
                  </Box>
                  <Box
                    sx={{
                      display: "table-cell",
                      py: 2,
                      fontWeight: "bold",
                      borderBottom: "1px solid",
                      borderColor: "divider",
                      textAlign: "right",
                    }}
                  >
                    Baths
                  </Box>
                </Box>
              </Box>
              {/* Table Body */}
              <Box sx={{ display: "table-row-group" }}>
                {(showAllListings
                  ? filteredListings
                  : filteredListings.slice(0, 10)
                ).map((listing) => (
                  <Box
                    key={listing.id}
                    sx={{
                      display: "table-row",
                      cursor: "pointer",
                      backgroundColor:
                        selectedListing?.id === listing.id ||
                        hoveredListing?.id === listing.id
                          ? "rgba(0, 0, 0, 0.04)"
                          : "transparent",
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.04)",
                      },
                    }}
                    onMouseEnter={() => setHoveredListing(listing)}
                    onMouseLeave={() => setHoveredListing(null)}
                    onClick={() => setSelectedListing(listing)}
                  >
                    <Box
                      sx={{
                        display: "table-cell",
                        py: 2,
                        borderBottom: "1px solid",
                        borderColor: "divider",
                        color: "primary.main",
                      }}
                    >
                      {listing.name}
                    </Box>
                    <Box
                      sx={{
                        display: "table-cell",
                        py: 2,
                        borderBottom: "1px solid",
                        borderColor: "divider",
                        textAlign: "right",
                      }}
                    >
                      ${listing.revenue.toLocaleString()}
                    </Box>
                    <Box
                      sx={{
                        display: "table-cell",
                        py: 2,
                        borderBottom: "1px solid",
                        borderColor: "divider",
                        textAlign: "right",
                      }}
                    >
                      ${listing.perNight.toLocaleString()}
                    </Box>
                    <Box
                      sx={{
                        display: "table-cell",
                        py: 2,
                        borderBottom: "1px solid",
                        borderColor: "divider",
                        textAlign: "right",
                      }}
                    >
                      {listing.occupancy}%
                    </Box>
                    <Box
                      sx={{
                        display: "table-cell",
                        py: 2,
                        borderBottom: "1px solid",
                        borderColor: "divider",
                        textAlign: "right",
                      }}
                    >
                      {listing.beds}
                    </Box>
                    <Box
                      sx={{
                        display: "table-cell",
                        py: 2,
                        borderBottom: "1px solid",
                        borderColor: "divider",
                        textAlign: "right",
                      }}
                    >
                      {listing.baths}
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>

          {/* Show All Button */}
          {filteredListings.length > 10 && (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <Button
                variant="text"
                onClick={() => setShowAllListings(!showAllListings)}
                sx={{ textTransform: "none" }}
              >
                {showAllListings
                  ? "Show Less"
                  : `Show All ${filteredListings.length} Listings`}
              </Button>
            </Box>
          )}
        </Box>
      </Paper>
    </Stack>
  );
};

export default PropertyComparables;
