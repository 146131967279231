/* eslint-disable react/prop-types */
import React from "react";

import PropTypes from "prop-types";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Tooltip as MuiTooltip,
} from "@mui/material";
import {
  PolarAngleAxis,
  PolarRadiusAxis,
  Radar,
  RadarChart,
  PolarGrid,
  Tooltip,
} from "recharts";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const MarketRadarCard = ({ data, title, tooltip }) => {
  const scores = Object.values(data);
  const subjects = Object.keys(data);

  const dataObj = scores.map((val, idx) => {
    // background key-value is used to draw background grey, making it look prettier
    return { subject: subjects[idx], score: parseFloat(val), background: 5 };
  });

  const CustomTooltip = ({ active, payload }) => {
    if (active) {
      return (
        <Typography variant="body1">{payload[0].payload.score}</Typography>
      );
    }

    return null;
  };

  const CustomPolarAngleAxis = ({ payload, x, y, cx, cy, ...rest }) => {
    return (
      <text
        {...rest}
        y={y + (y - cy) * 0.08} // this technique is used to expand labels outward by a bit, otherwise it can overlap with the hexagon
        x={x + (x - cx) * 0.08}
      >
        {payload.value}
      </text>
    );
  };

  return (
    <Card sx={{ height: "350px", m: "15px" }} elevation={3}>
      <CardContent sx={{ height: "350px" }}>
        <Box display="flex" justifyContent="space-between">
          <Typography color="textPrimary" gutterBottom>
            {title}
          </Typography>
          <MuiTooltip enterTouchDelay={100} title={tooltip}>
            <InfoOutlinedIcon fontSize="small" color="disabled" />
          </MuiTooltip>
        </Box>
        <Box
          display="flex"
          justifyContent="space-around"
          alignItems="center"
          p={0}
          m={0}
          height="280px"
        >
          <RadarChart
            cx="50%"
            cy="50%"
            outerRadius="80%"
            width={350}
            height={250}
            data={dataObj}
          >
            <PolarGrid radialLines />
            <PolarAngleAxis dataKey="subject" tick={<CustomPolarAngleAxis />} />
            <PolarRadiusAxis
              tickCount={5}
              axisLine={false}
              tick={false}
              domain={[4, 5]}
            />
            <Radar
              dataKey="score"
              stroke="#8884d8"
              fill="#8884d8"
              fillOpacity={0.7}
              strokeWidth={2}
              dot={{ stroke: "#8884d8", fill: "#8884d8", r: 2 }}
            />
            <Radar
              name=""
              dataKey="background"
              stroke="#f0f0f0"
              fill="#f0f0f0"
              fillOpacity={0.6}
            />
            <Tooltip content={<CustomTooltip />} />
          </RadarChart>
          <Box pr={5}>
            <Typography variant="body1" noWrap>
              Average score:{" "}
              {Math.round((scores.reduce((a, b) => a + b) / 6) * 100) / 100}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

MarketRadarCard.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
};

export default MarketRadarCard;
