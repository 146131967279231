import React from "react";
import PropTypes from "prop-types";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import {
  Box,
  Paper,
  Typography,
  Slider,
  Stack,
  Button,
  TextField,
  Grid,
  InputAdornment,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import NumberFormat from "react-number-format";

const months = ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"];

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <Paper sx={{ p: 1.5, backgroundColor: "rgba(255, 255, 255, 0.95)" }}>
        <Typography variant="body2" fontWeight="medium">
          <NumberFormat
            value={payload[0].value}
            displayType="text"
            thousandSeparator
            prefix="$"
          />
        </Typography>
        <Typography variant="caption" color="text.secondary">
          {payload[0].payload.month}
        </Typography>
      </Paper>
    );
  }
  return null;
};

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      payload: PropTypes.shape({
        month: PropTypes.string,
      }),
    })
  ),
};

CustomTooltip.defaultProps = {
  active: false,
  payload: [],
};

function OccupancyRateField({ value, onChange }) {
  return (
    <NumberFormat
      value={value}
      suffix="%"
      customInput={TextField}
      decimalScale={0}
      allowNegative={false}
      fullWidth
      size="small"
      onValueChange={(values) => {
        onChange(null, values.floatValue);
      }}
    />
  );
}

OccupancyRateField.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

const RevenueSeasonality = ({
  data,
  occupancyRate,
  avgDailyRate,
  onOccupancyChange,
  onDailyRateChange,
  occupancyPercentile,
  dailyRatePercentile,
  predictedRevenue,
  predictedOccupancy,
  predictedDailyRate,
  revenuePercentile,
  setIsCustomized,
}) => {
  const theme = useTheme();

  const handleDailyRateInput = (event) => {
    const value = Math.min(1000000000, Math.max(1, Number(event.target.value)));
    onDailyRateChange(null, value);
  };

  return (
    <Paper
      sx={{
        p: 3,
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        backdropFilter: "blur(10px)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 3,
        }}
      >
        <Typography variant="h6">Basics</Typography>
        <Button
          variant="outlined"
          size="small"
          onClick={() => {
            setIsCustomized(false);
            onOccupancyChange(null, predictedOccupancy);
            onDailyRateChange(null, predictedDailyRate);
          }}
          sx={{
            textTransform: "none",
            minWidth: "unset",
            px: 2,
          }}
        >
          Reset
        </Button>
      </Box>

      <Grid container spacing={4} sx={{ mb: 4 }}>
        <Grid item xs={12} md={6}>
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Occupancy Rate
            </Typography>
            <Box display="flex" alignItems="center" gap={1} mb={2}>
              <OccupancyRateField
                value={occupancyRate}
                onChange={onOccupancyChange}
              />
            </Box>
            <Typography variant="body2" color="text.secondary" gutterBottom>
              Top {Math.min(Math.max(occupancyPercentile, 1), 99)}% of
              comparables
            </Typography>
            <Slider
              value={occupancyRate}
              onChange={onOccupancyChange}
              min={0}
              max={100}
              valueLabelDisplay="auto"
              valueLabelFormat={(value) => `${value}%`}
              sx={{
                "& .MuiSlider-thumb": {
                  height: 16,
                  width: 16,
                  border: "2px solid currentColor",
                },
              }}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Avg Daily Rate
            </Typography>
            <Box display="flex" alignItems="center" gap={1} mb={2}>
              <TextField
                fullWidth
                value={avgDailyRate}
                onChange={handleDailyRateInput}
                InputProps={{
                  startAdornment: <Typography component="span">$</Typography>,
                  inputProps: {
                    min: 1,
                    max: 1000000000,
                    style: {
                      appearance: "textfield",
                      "-moz-appearance": "textfield",
                      "&::-webkit-outer-spin-button": {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                      "&::-webkit-inner-spin-button": {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                    },
                  },
                }}
                size="small"
              />
            </Box>
            <Typography variant="body2" color="text.secondary" gutterBottom>
              Top {Math.min(Math.max(dailyRatePercentile, 1), 99)}% of
              comparables
            </Typography>
            <Slider
              value={avgDailyRate}
              onChange={onDailyRateChange}
              min={0}
              max={1000}
              valueLabelDisplay="auto"
              valueLabelFormat={(value) => `$${value}`}
              sx={{
                "& .MuiSlider-thumb": {
                  height: 16,
                  width: 16,
                  border: "2px solid currentColor",
                },
              }}
            />
          </Box>
        </Grid>
      </Grid>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={4}
      >
        <Box>
          <Typography variant="h6" fontWeight="medium">
            Seasonality
          </Typography>
        </Box>
        <Box display="flex" gap={2} alignItems="center">
          <Box display="flex" alignItems="center" gap={0.5}>
            <Box
              sx={{
                width: 12,
                height: 12,
                backgroundColor: theme.palette.primary.main,
                borderRadius: "2px",
              }}
            />
            <Typography variant="body2" color="text.secondary">
              Revenue
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" gap={0.5}>
            <Box
              sx={{
                width: 12,
                height: 12,
                backgroundColor: theme.palette.grey[200],
                borderRadius: "2px",
              }}
            />
            <Typography variant="body2" color="text.secondary">
              Profit
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box sx={{ width: "100%", height: 300, mb: 4 }}>
        <ResponsiveContainer>
          <BarChart
            data={data}
            margin={{
              top: 5,
              right: 5,
              left: 5,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis
              dataKey="month"
              axisLine={false}
              tickLine={false}
              tick={{ fontSize: 12 }}
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              tick={{ fontSize: 12 }}
              tickFormatter={(value) => `$${value / 1000}k`}
            />
            <Tooltip content={<CustomTooltip />} />
            <Bar
              dataKey="revenue"
              fill={theme.palette.primary.main}
              radius={[4, 4, 0, 0]}
              maxBarSize={40}
            />
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </Paper>
  );
};

RevenueSeasonality.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      month: PropTypes.string,
      revenue: PropTypes.number,
      profit: PropTypes.number,
    })
  ).isRequired,
  occupancyRate: PropTypes.number.isRequired,
  avgDailyRate: PropTypes.number.isRequired,
  onOccupancyChange: PropTypes.func.isRequired,
  onDailyRateChange: PropTypes.func.isRequired,
  occupancyPercentile: PropTypes.number.isRequired,
  dailyRatePercentile: PropTypes.number.isRequired,
  predictedRevenue: PropTypes.number.isRequired,
  predictedOccupancy: PropTypes.number.isRequired,
  predictedDailyRate: PropTypes.number.isRequired,
  revenuePercentile: PropTypes.number.isRequired,
  setIsCustomized: PropTypes.func.isRequired,
};

export default RevenueSeasonality;
