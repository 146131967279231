import {
  ROOM_TYPES,
  ENTIRE_HOME,
  PRIVATE_ROOM,
  SHARED_ROOM,
  HOTEL,
} from "../constants/roomTypes";

import sharedBirdseye from "../assets/markers/all_4.svg";
import shared7 from "../assets/markers/all_30.svg";
import shared6 from "../assets/markers/all_27.svg";
import shared5 from "../assets/markers/all_24.svg";
import shared4 from "../assets/markers/all_21.svg";
import shared3 from "../assets/markers/all_18.svg";
import shared2 from "../assets/markers/all_15.svg";
import shared1 from "../assets/markers/all_12.svg";

import { DEFAULT_ZOOM_LEVEL } from "../constants/defaultValues";

// we are intentionally using the same color for property types in the latest 2024 change
// though historically we used different colors, but they looked noisy
const zoomOutMarkersMap = {
  [ROOM_TYPES[ENTIRE_HOME]]: sharedBirdseye,
  [ROOM_TYPES[PRIVATE_ROOM]]: sharedBirdseye,
  [ROOM_TYPES[SHARED_ROOM]]: sharedBirdseye,
  [ROOM_TYPES[HOTEL]]: sharedBirdseye,
};

const zoomInMarkersMap = {
  [ROOM_TYPES[ENTIRE_HOME]]: [
    shared1,
    shared2,
    shared3,
    shared4,
    shared5,
    shared6,
    shared7,
  ],
  [ROOM_TYPES[PRIVATE_ROOM]]: [
    shared1,
    shared2,
    shared3,
    shared4,
    shared5,
    shared6,
    shared7,
  ],
  [ROOM_TYPES[SHARED_ROOM]]: [
    shared1,
    shared2,
    shared3,
    shared4,
    shared5,
    shared6,
    shared7,
  ],
  // for now use same colors for hotel and shared rooms, since there are very few hotels
  // todo: consider excluding hotels from the backend
  [ROOM_TYPES[HOTEL]]: [
    shared1,
    shared2,
    shared3,
    shared4,
    shared5,
    shared6,
    shared7,
  ],
};

export const assignColor = (roomType) => {
  switch (roomType) {
    case ROOM_TYPES[ENTIRE_HOME]:
      return "#FF5A5F";
    case ROOM_TYPES[PRIVATE_ROOM]:
      return "#82ca9d";
    case ROOM_TYPES[SHARED_ROOM]:
      return "#83a6ed";
    case ROOM_TYPES[HOTEL]:
      return "#ffc658";
    default:
      return "red"; // this means error happened
  }
};

export const calculateMarkers = (listings, zoomLevel) => {
  /*
    TODO: consider this ?
      if revenue > 80 percentile, biggest size
      if reveune < 10 percentile, smallest size
  */

  if (listings == null || listings.length === 0) {
    return [];
  }

  const min = Math.min(...listings.map((listing) => listing.revenue));
  const max = Math.max(...listings.map((listing) => listing.revenue));

  const distance = max - min;

  return listings.map((listing, idx) => {
    const roomType = listing.listingType;
    const propertyMarkers = zoomInMarkersMap[roomType];

    let pos = Math.trunc(
      ((listing.revenue - min) / distance) * propertyMarkers.length
    ); // use Math.trunc instead of round so that the end range near max value isn't over-represented
    pos = Math.min(propertyMarkers.length - 1, pos);

    const icon =
      zoomLevel >= DEFAULT_ZOOM_LEVEL + 1
        ? propertyMarkers[pos]
        : zoomOutMarkersMap[roomType];

    return { icon, zIndex: idx };
  });
};

export const DISTINCT_COLORS = [
  "#8884d8",
  "#82ca9d",
  "#ffc658",
  "#f08080",
  "#66b5f7",
  "#c2e04d",
];

export const TREE_MAP_COLORS = [
  "#8884d8",
  "#82ca9d",
  "#ffc658",
  "#f08080",
  "#66b5f7",
  "#c2e04d",
];

export const BLUISH_COLORS = [
  "#72b3fc",
  "#318EF8",
  "#3a80cf",
  "#0061cf",
  "#00418b",
];

export const GREENISH_COLORS = [
  "#afe89e",
  "#89d971",
  "#7fc769",
  "#6BA559",
  "#548246",
];

export const GREEN_GRADIENT = [
  "#004616",
  "#207230",
  "#409F43",
  "#89C083",
  "#B4DCAA",
  "#DCF0D5",
  "#F6FBF4",
];

export const GREEN_SHADES = [
  "#f6faf3",
  "#e9f5e3",
  "#d3eac8",
  "#afd89d",
  "#82bd69",
  "#61a146",
  "#4c8435",
  "#3d692c",
  "#d3d3d3", // special color used for undefined
];

export const ORANGE_SHADES = [
  "#fffbea",
  "#fff2c5",
  "#ffe485",
  "#ffd046",
  "#ffba1b",
  "#fe9600",
  "#e26f00",
  "#bb4b02",
  "#d3d3d3", // special color used for undefined
];

export const DIVERGING_GRADIENT_MAP = {
  "#8884d8": "Extremely Low",
  "#a49edf": "Low",
  "#beb9e5": "Slightly Low",
  "#d8d4eb": "Average",
  "#ffceb8": "Average",
  "#ffab81": "Slightly High",
  "#ff874b": "High",
  "#ff6000": "Extremely High",
  "#d3d3d3": "Unknown", // special color used for undefined demand
};

// display names correspond to DIVERGING_GRADIENT
export const DIVERGING_GRADIENT = Object.keys(DIVERGING_GRADIENT_MAP);
