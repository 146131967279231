import React from "react";
import Card from "@mui/material/Card";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import { styled } from "@mui/material/styles";
import calculateReturn from "../util/roi";

const styles = {
  image: {
    height: "150px",
  },
};
const StyledCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== "selected",
})(({ selected }) => ({
  margin: "0px 4px 4px 4px",
  ...(selected && {
    border: "0px solid",
    boxShadow: "0px 0px 2px 2px #888",
  }),
}));

const ForSaleListingCard = ({ listing, isSelected }) => {
  const bullet = (
    <Box
      component="span"
      sx={{
        display: "inline-block",
        margin: "0 2px",
        transform: "scale(0.8)",
      }}
    >
      •
    </Box>
  );

  const { cashOnCash, capitalizationRate } = calculateReturn({
    propertyValue: listing.estimatePrice,
    monthlyRent: listing.estimateRent,
    monthlyExpense: listing.estimatePrice * 0.002,
    downPayment: listing.estimatePrice * 0.2,
    interestRate: 3.125,
  });

  const fullAddress = [
    [listing.addressStreet, listing.addressCity, listing.addressState].join(
      ","
    ),
    listing.addressZipCode,
  ].join(" ");
  const encodedAddress = fullAddress.replace(/ /g, "-");
  const analyzerRoute = `/analyzer/${encodedAddress}`;

  return (
    <Link href={analyzerRoute} target="_blank" rel="noopener" underline="none">
      <StyledCard selected={isSelected}>
        <CardMedia sx={styles.image} image={listing.photoURL} />
        <CardContent>
          <Typography color="textPrimary" gutterBottom>
            $
            <NumberFormat
              value={listing.listingPrice}
              displayType="text"
              decimalScale={0}
              thousandsGroupStyle="thousand"
              thousandSeparator
            />
          </Typography>
          <Typography variant="body2">
            {listing.beds} beds
            {bullet}
            {listing.beds} baths
            {bullet}
            {listing.livingArea} Sq. Ft.
          </Typography>
          <Typography variant="body2" gutterBottom>
            Cash-on-Cash: {cashOnCash}%{bullet}
            Cap-Rate: {capitalizationRate}%
          </Typography>
          <Typography variant="body2">
            {listing.addressStreet}, {listing.addressCity},{" "}
            {listing.addressState} {listing.addressZipCode}
          </Typography>
        </CardContent>
      </StyledCard>
    </Link>
  );
};

ForSaleListingCard.propTypes = {
  listing: PropTypes.shape({
    listingId: PropTypes.string.isRequired,
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
    photoURL: PropTypes.string.isRequired,
    beds: PropTypes.number.isRequired,
    baths: PropTypes.number.isRequired,
    addressStreet: PropTypes.string.isRequired,
    addressCity: PropTypes.string.isRequired,
    addressState: PropTypes.string.isRequired,
    addressZipCode: PropTypes.string.isRequired,
    livingArea: PropTypes.number.isRequired,
    lotSize: PropTypes.number.isRequired,
    listingPrice: PropTypes.number.isRequired,
    estimatePrice: PropTypes.number.isRequired,
    estimateRent: PropTypes.number.isRequired,
  }).isRequired,
  isSelected: PropTypes.bool.isRequired,
};

export default ForSaleListingCard;
