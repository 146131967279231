import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import Skeleton from "@mui/material/Skeleton";
import NumberFormat from "react-number-format";
import { styled } from "@mui/material/styles";
import { Link } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import CloseIcon from "@mui/icons-material/Close";
import ShortcutIcon from "@mui/icons-material/Shortcut";
import HomeIcon from "@mui/icons-material/Home";
import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from "react-redux";
import { fetchAirbnbListingsDetails } from "../actions/airbnbListingsActions";
import { selectAtlasCurrencySymbol } from "../selectors/airbnbListingsSelectors";

const styles = {
  image: {
    height: "150px",
  },
};

const StyledCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== "selected",
})(({ selected }) => ({
  margin: "0px 4px 4px 4px",
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  ...(selected && {
    border: "0px solid",
    boxShadow: "0px 0px 2px 2px rgb(25, 118, 210)",
  }),
}));

const AirbnbListingCard = ({
  listing,
  listingDetail,
  isSelected,
  isMapOverlay,
  setSelectedListingIdx,
}) => {
  const [isImageError, setIsImageError] = useState(false);
  const currencySymbol = useSelector(selectAtlasCurrencySymbol);
  const queryCurrency = useSelector(
    (state) => state.airbnbListings.query.currency
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (listingDetail == null) {
      dispatch(fetchAirbnbListingsDetails([listing.id], queryCurrency));
    }
  }, [listingDetail, queryCurrency]);

  useEffect(() => {
    if (listingDetail != null && listingDetail.photoURL != null) {
      setIsImageError(false);
    }
  }, [listingDetail]);

  const renderFallbackImage = () => (
    <Box
      sx={{
        ...styles.image,
        backgroundColor: "#f5f5f5",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <HomeIcon sx={{ fontSize: 48, color: "#999" }} />
    </Box>
  );

  const bullet = (
    <Box
      component="span"
      sx={{
        display: "inline-block",
        margin: "0 2px",
        transform: "scale(0.8)",
      }}
    >
      •
    </Box>
  );

  if (listingDetail == null) {
    return null;
  }

  const {
    listingName,
    revenue,
    daysOccupied,
    listingType,
    averageDailyRate,
    bedrooms,
  } = listingDetail;
  const occupancyRate = ((daysOccupied / 365.0) * 100).toFixed(1);

  return (
    <StyledCard selected={isSelected}>
      {!listingDetail.detail ? (
        <Skeleton
          variant="rectangular"
          animation="wave"
          height={300}
          sx={{ borderRadius: "4px", bgcolor: "#fff" }}
        />
      ) : (
        <>
          {isImageError ? (
            renderFallbackImage()
          ) : (
            <CardMedia
              component="img"
              sx={styles.image}
              image={listingDetail.photoURL}
              onError={() => setIsImageError(true)}
            />
          )}
          {isSelected && (
            <Link
              href={`https://www.airbnb.com/rooms/${listingDetail.listingId}`}
              target="_blank"
              rel="noopener"
            >
              <ShortcutIcon
                sx={{
                  backgroundColor: "#222",
                  color: "white",
                  top: "10px",
                  right: "10px",
                  position: "absolute",
                  borderRadius: "4px",
                  padding: "2px",
                }}
              />
            </Link>
          )}
          {isMapOverlay && (
            <IconButton
              sx={{
                top: "0px",
                right: "0px",
                position: "absolute",
              }}
              onClick={() => {
                setSelectedListingIdx(null);
              }}
            >
              <Box
                sx={{
                  backgroundColor: "black", // Circle color
                  borderRadius: "50%",
                  width: 24,
                  height: 24,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CloseIcon sx={{ color: "white", fontSize: 16 }} />
              </Box>
            </IconButton>
          )}
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
            }}
          >
            <Box mb={1}>
              <Typography variant="body2" fontWeight="bold">
                {listingName}
              </Typography>
              <Typography variant="body2" gutterBottom sx={{ color: "#666" }}>
                {listingType}
                {listingType && bullet}
                {bedrooms <= 1 ? "1 room" : `${bedrooms} rooms`}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between" marginTop="auto">
              <Box display="flex" flexDirection="column" alignItems="center">
                <Typography variant="body2" fontWeight="bold">
                  <NumberFormat
                    value={revenue}
                    displayType="text"
                    decimalScale={0}
                    thousandsGroupStyle="thousand"
                    thousandSeparator
                    prefix={currencySymbol}
                  />
                </Typography>
                <Typography variant="body2">Revenue</Typography>
              </Box>

              <Box display="flex" flexDirection="column" alignItems="center">
                <Typography variant="body2" fontWeight="bold">
                  {occupancyRate}%
                </Typography>
                <Typography variant="body2">Occupancy</Typography>
              </Box>

              <Box display="flex" flexDirection="column" alignItems="center">
                <Typography variant="body2" fontWeight="bold">
                  <NumberFormat
                    value={averageDailyRate}
                    displayType="text"
                    decimalScale={0}
                    thousandsGroupStyle="thousand"
                    thousandSeparator
                    prefix={currencySymbol}
                  />
                </Typography>

                <Typography variant="body2">Daily Rate</Typography>
              </Box>
            </Box>
          </CardContent>
        </>
      )}
    </StyledCard>
  );
};

export default AirbnbListingCard;
