/* eslint-disable react/prop-types */
/* eslint-disable react/prefer-stateless-function */
import {
  Box,
  Divider,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import {
  fakeADR,
  fakeOccupancy,
  fakeRevenue,
  fakeReviewScores,
  fakeRoomTypes,
  fakeAvailableNights,
  fakeBookedNights,
  fakeOccupancyBreakdown,
} from "../util/dataUtil";
import MarketSimpleLineCard from "./MarketSimpleLineCard";
import MarketGradeCard from "./MarketGradeCard";
import MarketReviewCard from "./charts/MarketRadarCard";
import MarketPieCard from "./charts/MarketPieCard";
import MarketADRTimeSeriesCard from "./MarketADRTimeSeriesCard";
import MarketOccupancyTimesSeriesCard from "./MarketOccupancyTimeSeriesCard";
import { MARGIN_BELOW_ATLAS_MENU } from "./cssvars";
import { marketSummaryPropType } from "../proptypes";

const styles = {
  tabs: {
    backgroundColor: "white",
  },
  tab: {
    minWidth: "0px",
    textTransform: "none",
  },
};

const MarketSummarySection = ({ months }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const priceData = months.map((month) => {
    return {
      date: new Date(month.date),
      value: month.priceAvg,
    };
  });

  const occupancyData = months.map((month) => {
    return {
      date: new Date(month.date),
      value: month.occupancyAvg,
    };
  });

  const revenueData = months.map((month) => {
    return {
      date: new Date(month.date),
      value: month.revenueAvg,
    };
  });

  return months.length > 0 ? (
    <Box
      display="flex"
      flexDirection={isMobile ? "column" : "row"}
      // minWidth="300px"
      // maxHeight="150px"
      gap={isMobile ? "8px" : "12px"}
      alignItems="center"
      justifyContent="center"
      mx={isMobile ? "18px" : 0}
      mb={isMobile ? 4 : MARGIN_BELOW_ATLAS_MENU}
    >
      {/* <MarketGradeCard /> */}
      <MarketSimpleLineCard data={priceData} type="ADR" color="blue" />
      <MarketSimpleLineCard
        data={occupancyData}
        type="OCCUPANCY"
        color="orange"
      />
      <MarketSimpleLineCard data={revenueData} type="REVENUE" color="green" />
    </Box>
  ) : null;
};

MarketSummarySection.prototype = marketSummaryPropType;

export default MarketSummarySection;
