import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import {
  Box,
  Paper,
  Typography,
  useTheme,
  Slider,
  Grid,
  Divider,
  Button,
} from "@mui/material";
import NumberFormat from "react-number-format";

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    // Calculate total value for ROI
    const totalValue = payload.reduce((sum, entry) => sum + entry.value, 0);
    const initialInvestment = payload[3].value; // downPayment is the initial investment
    const roi = ((totalValue - initialInvestment) / initialInvestment) * 100;

    return (
      <Paper sx={{ p: 1.5, backgroundColor: "rgba(255, 255, 255, 0.95)" }}>
        <Box sx={{ mb: 1.5, textAlign: "center" }}>
          <Typography variant="caption" color="text.secondary">
            Year {payload[0].payload.year}
          </Typography>
        </Box>
        <Divider sx={{ mb: 1.5 }} />
        {payload.map((entry, index) => (
          <Box key={index} mb={index < payload.length - 1 ? 1 : 0}>
            <Typography variant="caption" color="text.secondary">
              {entry.name}
            </Typography>
            <Typography variant="body2" fontWeight="medium">
              <NumberFormat
                value={entry.value}
                displayType="text"
                thousandSeparator
                prefix="$"
              />
            </Typography>
          </Box>
        ))}
        <Box
          sx={{
            mt: 2,
            pt: 1.5,
            borderTop: "1px solid rgba(0, 0, 0, 0.1)",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography
              variant="caption"
              sx={{ color: "primary.main", fontWeight: "medium" }}
            >
              Return on Investment
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: "primary.main", fontWeight: "bold" }}
            >
              {roi.toFixed(1)}%
            </Typography>
          </Box>
        </Box>
      </Paper>
    );
  }
  return null;
};

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
      payload: PropTypes.shape({
        year: PropTypes.number,
      }),
    })
  ),
};

CustomTooltip.defaultProps = {
  active: false,
  payload: [],
};

const InvestmentProjection = ({ data, onAppreciationChange }) => {
  const theme = useTheme();
  const DEFAULT_PROPERTY_APPRECIATION = 12;
  const DEFAULT_REVENUE_APPRECIATION = 9;

  const [propertyAppreciation, setPropertyAppreciation] = useState(
    DEFAULT_PROPERTY_APPRECIATION
  );
  const [revenueAppreciation, setRevenueAppreciation] = useState(
    DEFAULT_REVENUE_APPRECIATION
  );

  const handlePropertyAppreciationChange = (event, newValue) => {
    setPropertyAppreciation(newValue);
    onAppreciationChange?.({
      property: newValue,
      revenue: revenueAppreciation,
    });
  };

  const handleRevenueAppreciationChange = (event, newValue) => {
    setRevenueAppreciation(newValue);
    onAppreciationChange?.({
      property: propertyAppreciation,
      revenue: newValue,
    });
  };

  const handleReset = () => {
    setPropertyAppreciation(DEFAULT_PROPERTY_APPRECIATION);
    setRevenueAppreciation(DEFAULT_REVENUE_APPRECIATION);
    onAppreciationChange?.({
      property: DEFAULT_PROPERTY_APPRECIATION,
      revenue: DEFAULT_REVENUE_APPRECIATION,
    });
  };

  return (
    <Paper
      sx={{
        p: 3,
        height: "100%",
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        backdropFilter: "blur(10px)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 1,
        }}
      >
        <Typography variant="h6" fontWeight="medium">
          Return on Investment
        </Typography>
        <Button
          variant="outlined"
          size="small"
          onClick={handleReset}
          sx={{
            textTransform: "none",
            minWidth: "unset",
            px: 2,
          }}
        >
          Reset
        </Button>
      </Box>

      <Grid container spacing={4} sx={{ mb: 3 }}>
        <Grid item xs={12} md={6}>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            Property Appreciation
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Slider
              value={propertyAppreciation}
              onChange={handlePropertyAppreciationChange}
              min={0}
              max={20}
              step={0.5}
              sx={{
                "& .MuiSlider-track": {
                  backgroundColor: theme.palette.info.main,
                },
                "& .MuiSlider-thumb": {
                  backgroundColor: theme.palette.info.main,
                  height: 16,
                  width: 16,
                  border: "2px solid currentColor",
                },
              }}
            />
            <Typography variant="body2" sx={{ ml: 2, minWidth: 35 }}>
              {propertyAppreciation}%
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            Revenue Appreciation
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Slider
              value={revenueAppreciation}
              onChange={handleRevenueAppreciationChange}
              min={0}
              max={20}
              step={0.5}
              sx={{
                "& .MuiSlider-track": {
                  backgroundColor: theme.palette.success.main,
                },
                "& .MuiSlider-thumb": {
                  backgroundColor: theme.palette.success.main,
                  height: 16,
                  width: 16,
                  border: "2px solid currentColor",
                },
              }}
            />
            <Typography variant="body2" sx={{ ml: 2, minWidth: 35 }}>
              {revenueAppreciation}%
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Box sx={{ width: "100%", height: 400 }}>
        <ResponsiveContainer>
          <AreaChart
            data={data}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis
              dataKey="year"
              tickFormatter={(value) => `Y${value}`}
              axisLine={false}
              tickLine={false}
              ticks={[1, 3, 5, 10]}
              domain={[1, 10]}
            />
            <YAxis
              tickFormatter={(value) => `$${value / 1000}k`}
              axisLine={false}
              tickLine={false}
            />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            <Area
              type="monotone"
              dataKey="profit"
              stackId="1"
              stroke={theme.palette.success.main}
              fill={theme.palette.success.light}
              name="Profit"
            />
            <Area
              type="monotone"
              dataKey="propertyAppreciation"
              stackId="1"
              stroke={theme.palette.info.main}
              fill={theme.palette.info.light}
              name="Property Appreciation"
            />
            <Area
              type="monotone"
              dataKey="principalPaydown"
              stackId="1"
              stroke={theme.palette.warning.main}
              fill={theme.palette.warning.light}
              name="Principal Pay-down"
            />
            <Area
              type="monotone"
              dataKey="downPayment"
              stackId="1"
              stroke={theme.palette.error.main}
              fill={theme.palette.error.light}
              name="Down Payment"
            />
          </AreaChart>
        </ResponsiveContainer>
      </Box>
    </Paper>
  );
};

InvestmentProjection.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      year: PropTypes.number.isRequired,
      profit: PropTypes.number.isRequired,
      propertyAppreciation: PropTypes.number.isRequired,
      principalPaydown: PropTypes.number.isRequired,
      downPayment: PropTypes.number.isRequired,
    })
  ).isRequired,
  onAppreciationChange: PropTypes.func,
};

InvestmentProjection.defaultProps = {
  onAppreciationChange: () => {},
};

export default InvestmentProjection;
