import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { HelmetProvider } from "react-helmet-async";
import theme from "./theme";
import RoutedApp from "./RoutedApp";
import SEO from "./SEO";

const App = () => {
  // SEO at this level serves as fallback for child pages which do not implement SEO component

  return (
    <HelmetProvider>
      <StyledEngineProvider>
        <ThemeProvider theme={theme}>
          <SEO />
          <CssBaseline />
          <RoutedApp />
        </ThemeProvider>
      </StyledEngineProvider>
    </HelmetProvider>
  );
};

export default App;
