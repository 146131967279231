/* eslint-disable react/prop-types */
import React from "react";
import { getMonthYearLabel } from "../util/dateutil";
import MarketLineCard from "./MarketLineCard";
import withErrorBoundary from "./error/withErrorBoundary";

const MarketADRTimeSeriesCard = ({ data }) => {
  const lineNames = ["25 pct", "50 pct", "75 pct", "90 pct"];

  data.sort((left, right) => left - right);
  const ADRData = data.map((monthly) => {
    return {
      date: getMonthYearLabel(monthly.date),
      ...Object.fromEntries(
        Object.entries(monthly.pricePercentiles).map(([k, v], _) => {
          return [`${parseInt(k)} pct`, v];
        })
      ),
    };
  });

  return (
    <MarketLineCard
      data={ADRData}
      lineNames={lineNames}
      activeLines={[lineNames[1], lineNames[2]]}
      label="ADR"
      tooltip="Average Daily Rate (ADR) percentile breakdown of 25th, 50th, 75th, and 90th for the past 12 months."
      yAxisShowCurrency
    />
  );
};

export default withErrorBoundary(MarketADRTimeSeriesCard);
