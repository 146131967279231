import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Grid, Paper, Typography, Divider, Tooltip } from "@mui/material";
import NumberFormat from "react-number-format";
import PropertyDetails from "./PropertyDetails";
import MetricCard from "./MetricCard";

const PropertyOverview = ({
  isCustomized,
  propertyDetails,
  onInputChange,
  predictions,
  searchInput,
  handlePlaceSelect,
  handleAddressSearch,
  isLoaded,
  calculatedMetrics,
  onCalculate,
}) => {
  const [isEditMode, setIsEditMode] = useState(false);

  return (
    <PropertyDetails.EditModeProvider
      value={{ isEditMode, setIsEditMode, onCalculate }}
    >
      <Paper
        sx={{
          p: 3,
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          backdropFilter: "blur(10px)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 3,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "45.83%",
              pr: 2, // Add right padding to account for Grid spacing
            }}
          >
            <Typography variant="h6">Property</Typography>
            <Box>
              <PropertyDetails.EditButtons />
            </Box>
          </Box>
          <Tooltip
            title={
              isCustomized
                ? "These metrics reflect your custom adjustments to occupancy and daily rates"
                : "These metrics show our AI-predicted baseline values for this property"
            }
            arrow
            placement="top"
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "transparent",
                color: isCustomized ? "success.main" : "primary.main",
                fontSize: "0.875rem",
                fontWeight: "bold",
                cursor: "help",
                transition: "all 0.2s ease",
                border: "2px solid",
                borderColor: isCustomized ? "success.light" : "primary.light",
                borderRadius: "12px",
                px: 1.5,
                py: 0.5,
                position: "relative",
                boxShadow: isCustomized
                  ? "0 0 8px rgba(76, 175, 80, 0.2)"
                  : "0 0 8px rgba(25, 118, 210, 0.2)",
                "&::before": {
                  content: '""',
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  background: isCustomized ? "success.light" : "primary.light",
                  opacity: 0.12,
                  borderRadius: "inherit",
                },
                "&:hover": {
                  backgroundColor: "transparent",
                  transform: "scale(1.02)",
                  boxShadow: isCustomized
                    ? "0 0 12px rgba(76, 175, 80, 0.3)"
                    : "0 0 12px rgba(25, 118, 210, 0.3)",
                  "&::before": {
                    opacity: 0.18,
                  },
                },
              }}
            >
              ✨{isCustomized ? "Customized" : "Baseline"}
            </Box>
          </Tooltip>
        </Box>

        <Grid container spacing={4}>
          {/* Left Column - Property Details */}
          <Grid item xs={12} md={5.5}>
            <PropertyDetails
              propertyDetails={propertyDetails}
              onInputChange={onInputChange}
              predictions={predictions}
              searchInput={searchInput}
              handlePlaceSelect={handlePlaceSelect}
              handleAddressSearch={handleAddressSearch}
              isLoaded={isLoaded}
            />
          </Grid>

          {/* Vertical Divider for MD+ screens */}
          <Grid
            item
            md={1}
            sx={{
              display: { xs: "none", md: "flex" },
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                height: "100%",
                borderRightWidth: 1,
                borderColor: "grey.300",
              }}
            />
          </Grid>

          {/* Horizontal Divider for XS-SM screens */}
          <Grid
            item
            xs={12}
            sx={{
              display: { xs: "block", md: "none" },
              my: 3,
            }}
          >
            <Divider sx={{ borderColor: "grey.300" }} />
          </Grid>

          {/* Right Column - Financial Metrics */}
          <Grid item xs={12} md={5.5}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <MetricCard
                  title="Annual Revenue"
                  value={
                    <NumberFormat
                      value={calculatedMetrics.estimatedRevenue * 12 || 0}
                      displayType="text"
                      thousandSeparator
                      prefix="$"
                    />
                  }
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <MetricCard
                  title="Occupancy Rate"
                  value={`${calculatedMetrics.occupancyRate || 0}%`}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <MetricCard
                  title="Average Daily Rate"
                  value={
                    <NumberFormat
                      value={calculatedMetrics.averageDailyRate || 0}
                      displayType="text"
                      thousandSeparator
                      prefix="$"
                    />
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </PropertyDetails.EditModeProvider>
  );
};

PropertyOverview.propTypes = {
  isCustomized: PropTypes.bool.isRequired,
  propertyDetails: PropTypes.object.isRequired,
  onInputChange: PropTypes.func.isRequired,
  predictions: PropTypes.array.isRequired,
  searchInput: PropTypes.string.isRequired,
  handlePlaceSelect: PropTypes.func.isRequired,
  handleAddressSearch: PropTypes.func.isRequired,
  isLoaded: PropTypes.bool.isRequired,
  calculatedMetrics: PropTypes.object.isRequired,
  onCalculate: PropTypes.func.isRequired,
};

export default PropertyOverview;
