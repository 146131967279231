import React from "react";
import PropTypes from "prop-types";
import { Paper, Typography } from "@mui/material";

const MetricCard = ({ title, value, subtitle }) => (
  <Paper
    sx={{
      p: 2,
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      border: "1px solid",
      borderColor: "grey.200",
      borderRadius: 2,
      backgroundColor: "white",
      minHeight: "unset",
    }}
  >
    <Typography
      variant="body2"
      color="text.secondary"
      sx={{
        textAlign: "center",
        mb: 1,
        minHeight: "unset",
      }}
    >
      {title}
    </Typography>
    <Typography
      variant="h6"
      sx={{
        fontWeight: "medium",
        textAlign: "center",
        whiteSpace: "nowrap",
      }}
    >
      {value}
    </Typography>
    {subtitle && (
      <Typography
        variant="body2"
        color="text.secondary"
        sx={{ mt: 1, textAlign: "center" }}
      >
        {subtitle}
      </Typography>
    )}
  </Paper>
);

MetricCard.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.node.isRequired,
  subtitle: PropTypes.string,
};

MetricCard.defaultProps = {
  subtitle: null,
};

export default MetricCard;
