import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Typography,
  Paper,
  TextField,
  Grid,
  Button,
  Divider,
  useTheme,
  useMediaQuery,
  Stack,
  Card,
  CardContent,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import TimelineIcon from "@mui/icons-material/Timeline";
import AssessmentIcon from "@mui/icons-material/Assessment";
import CompareIcon from "@mui/icons-material/Compare";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const StyledHeroSection = styled(Box)(({ theme }) => ({
  background:
    "linear-gradient(180deg, rgb(235, 245, 255) 0%, rgba(250, 250, 250, 0.8) 100%)",
  padding: theme.spacing(12, 0),
  textAlign: "center",
  position: "relative",
  overflow: "hidden",
}));

const StyledSearchBox = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(0.5),
  display: "flex",
  alignItems: "center",
  width: "100%",
  maxWidth: 600,
  height: 48,
  margin: "0 auto",
  marginTop: theme.spacing(4),
  backgroundColor: "rgba(255, 255, 255, 0.9)",
  backdropFilter: "blur(10px)",
  border: "1px solid",
  borderColor: theme.palette.grey[200],
  "&:hover": {
    borderColor: theme.palette.primary.main,
  },
}));

const FeatureCard = styled(Card)(({ theme }) => ({
  height: "100%",
  backgroundColor: "rgba(255, 255, 255, 0.8)",
  backdropFilter: "blur(10px)",
  transition: "transform 0.2s ease-in-out",
  "&:hover": {
    transform: "translateY(-4px)",
  },
}));

const StepCard = styled(Paper)(({ theme }) => ({
  height: "100%",
  padding: theme.spacing(4),
  backgroundColor: "rgba(255, 255, 255, 0.8)",
  backdropFilter: "blur(10px)",
  border: "1px solid",
  borderColor: theme.palette.grey[200],
  transition: "transform 0.2s ease-in-out",
  "&:hover": {
    transform: "translateY(-4px)",
    boxShadow: theme.shadows[4],
  },
}));

const CalculatorPromo = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState("");

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchInput.trim()) {
      navigate("/calculator", { state: { address: searchInput } });
    }
  };

  const features = [
    {
      icon: <MonetizationOnIcon fontSize="large" color="primary" />,
      title: "Revenue Prediction",
      description:
        "Get accurate revenue projections based on real market data from millions of properties worldwide.",
    },
    {
      icon: <TimelineIcon fontSize="large" color="primary" />,
      title: "Seasonality Analysis",
      description:
        "Understand how your property performs throughout the year with detailed seasonal trends.",
    },
    {
      icon: <AssessmentIcon fontSize="large" color="primary" />,
      title: "Financial Metrics",
      description:
        "Calculate key metrics including Cap Rate, Cash on Cash Return, and ROI with full transparency.",
    },
    {
      icon: <CompareIcon fontSize="large" color="primary" />,
      title: "Market Comparables",
      description:
        "Compare your property with similar listings in the area to optimize your pricing strategy.",
    },
    {
      icon: <TrendingUpIcon fontSize="large" color="primary" />,
      title: "Investment Projections",
      description:
        "View detailed 10-year projections including property appreciation and rental income growth.",
    },
    {
      icon: <PriceCheckIcon fontSize="large" color="primary" />,
      title: "Operating Costs",
      description:
        "Factor in all expenses including mortgage, management fees, taxes, and maintenance costs.",
    },
  ];

  const steps = [
    {
      number: "1",
      title: "Enter Property Details",
      description:
        "Input your property address and basic information like bedrooms and bathrooms. Our calculator instantly finds relevant comparables.",
    },
    {
      number: "2",
      title: "Review Market Data",
      description:
        "Analyze comprehensive market insights including occupancy rates, daily rates, and seasonal trends from actual Airbnb listings.",
    },
    {
      number: "3",
      title: "Calculate ROI",
      description:
        "Get detailed financial projections including Cap Rate, Cash on Cash Return, and 10-year investment analysis.",
    },
  ];

  const faqs = [
    {
      question: "How accurate are your Airbnb revenue estimates?",
      answer:
        "AirROI's revenue calculator analyzes data from 50-100 comparable properties in your area, providing highly accurate estimates based on real performance data. We consider factors like seasonality, local events, and market trends to give you the most precise projections possible.",
    },
    {
      question: "What metrics are included in the rental property analysis?",
      answer:
        "We provide comprehensive metrics including Average Daily Rate (ADR), Occupancy Rate, RevPAR, Cap Rate, Cash on Cash Return, ROI, Net Operating Income, and detailed 10-year projections with property appreciation. Our analysis helps you make data-driven investment decisions.",
    },
    {
      question:
        "How does your calculator compare to other vacation rental calculators?",
      answer:
        "Unlike basic calculators, we provide full transparency in our calculations and use actual market data from successful Airbnb listings. Our tool considers over 20 different factors including seasonal demand, local events, and competitor pricing to give you the most accurate revenue estimates.",
    },
    {
      question: "Can I analyze short-term rental potential for any location?",
      answer:
        "Yes, our calculator works for any property worldwide. We analyze comparable properties in your specific area to provide location-specific insights, helping you understand the true earning potential of your vacation rental.",
    },
    {
      question: "How often is your market data updated?",
      answer:
        "Our database is updated near real-time with the latest pricing, occupancy rates, and market trends. This ensures you always have access to current data for making informed decisions about your Airbnb investment.",
    },
    {
      question: "What makes a good Airbnb investment property?",
      answer:
        "A successful Airbnb property typically has a good location, competitive amenities, and strong market demand. Our calculator helps you evaluate these factors by analyzing actual performance data from similar properties in your area.",
    },
  ];

  return (
    <Box>
      <StyledHeroSection>
        <Container maxWidth="lg">
          <Typography
            variant={isMobile ? "h4" : "h3"}
            component="h1"
            sx={{
              fontWeight: "bold",
              mb: 2,
              background: "linear-gradient(45deg, #1976d2, #42a5f5)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Airbnb Revenue Calculator
          </Typography>
          <Typography
            variant="h6"
            sx={{
              color: "text.secondary",
              maxWidth: 800,
              mx: "auto",
              mb: 4,
            }}
          >
            Maximize your vacation rental income with our data-driven
            calculator. Get instant revenue estimates and optimize your Airbnb
            pricing strategy.
          </Typography>

          <Box
            sx={{ mt: 4, display: "flex", justifyContent: "center", gap: 2 }}
          >
            <Typography variant="body2" color="text.secondary">
              ✓ Industry-leading accuracy
            </Typography>
            <Typography variant="body2" color="text.secondary">
              ✓ Real-time market data
            </Typography>
            <Typography variant="body2" color="text.secondary">
              ✓ Free instant estimates
            </Typography>
          </Box>
          <form onSubmit={handleSearch}>
            <StyledSearchBox>
              <TextField
                fullWidth
                placeholder="Enter property address..."
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                  "& .MuiOutlinedInput-root": { padding: 1 },
                }}
              />
              <IconButton
                type="submit"
                color="primary"
                sx={{
                  p: "10px",
                  "&:hover": { backgroundColor: "primary.light" },
                }}
              >
                <SearchIcon />
              </IconButton>
            </StyledSearchBox>
          </form>
        </Container>
      </StyledHeroSection>

      <Container maxWidth="lg" sx={{ pb: 8, pt: 3 }}>
        <Typography variant="h4" align="center" gutterBottom>
          Key Features
        </Typography>
        <Typography
          variant="body1"
          color="text.secondary"
          align="center"
          sx={{ maxWidth: 800, mx: "auto", mb: 6 }}
        >
          Everything you need to make data-driven investment decisions for your
          Airbnb property
        </Typography>

        <Grid container spacing={4}>
          {features.map((feature, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <FeatureCard>
                <CardContent sx={{ textAlign: "center", p: 3 }}>
                  <Box sx={{ mb: 2 }}>{feature.icon}</Box>
                  <Typography variant="h6" gutterBottom>
                    {feature.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {feature.description}
                  </Typography>
                </CardContent>
              </FeatureCard>
            </Grid>
          ))}
        </Grid>

        <Box sx={{ mt: 12, mb: 6 }}>
          <Grid container spacing={6} alignItems="flex-start">
            <Grid item xs={12} md={6}>
              <Typography variant="h4" gutterBottom align="left">
                How It Works
              </Typography>
              <Stack spacing={3}>
                <Typography variant="body1">
                  When you enter an address, we analyze nearby properties to
                  create a relevance-based index. Properties with similar
                  characteristics have a higher impact on our calculations.
                </Typography>
                <Typography variant="body1">
                  Our calculator considers crucial market metrics including
                  seasonal patterns, local demand trends, and historical revenue
                  growth in your area.
                </Typography>
                <Typography variant="body1">
                  By leveraging data from comparable properties, we provide
                  precise earning potential estimates for any address worldwide,
                  helping you make informed investment decisions.
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  width: "100%",
                  height: 400,
                  backgroundColor: "grey.100",
                  borderRadius: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography variant="body1" color="text.secondary">
                  [Placeholder for calculator workflow illustration]
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ mt: 12, mb: 6 }}>
          <Grid container spacing={6} alignItems="center">
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  width: "100%",
                  height: 400,
                  backgroundColor: "grey.100",
                  borderRadius: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography variant="body1" color="text.secondary">
                  [Placeholder for earnings illustration]
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h4" gutterBottom align="left">
                How Much Can I Make on Airbnb?
              </Typography>
              <Stack spacing={3}>
                <Typography variant="body1">
                  The basic formula for estimating your Airbnb revenue is:
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    my: 2,
                  }}
                >
                  Annual Revenue = Occupancy Rate × Daily Rate × 365 days
                </Typography>
                <Typography variant="body1">
                  For example, with an 70% occupancy rate and $300 average daily
                  rate, your potential annual revenue would be around $76,650
                  before expenses and taxes.
                </Typography>
                <Typography variant="body1">
                  Most calculators in the market lack transparency in their
                  calculations. Our calculator provides full visibility into the
                  computation process, using 50 to 100 comparable properties to
                  give you accurate, data-driven estimates.
                </Typography>
                <Typography variant="body1">
                  Whether you're a host, property manager, investor, or real
                  estate agent, we make it easy to access the data you need to
                  make informed decisions about your short-term rental business.
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ mt: 12, mb: 12 }}>
          <Typography variant="h4" align="center" gutterBottom>
            Frequently Asked Questions
          </Typography>
          <Box sx={{ mt: 6 }}>
            {faqs.map((faq, index) => (
              <Accordion
                key={index}
                sx={{
                  boxShadow: "none",
                  "&:before": { display: "none" },
                  borderBottom: 1,
                  borderColor: "divider",
                  background: "transparent",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  sx={{
                    px: 0,
                    "& .MuiAccordionSummary-content": { margin: "24px 0" },
                  }}
                >
                  <Typography variant="h6" sx={{ fontWeight: 500 }}>
                    {faq.question}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ px: 0, pb: 3 }}>
                  <Typography variant="body1" color="text.secondary">
                    {faq.answer}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </Box>

        <Box sx={{ mt: 8, mb: 6, textAlign: "center" }}>
          <Typography variant="h4" gutterBottom>
            Make Informed Investment Decisions
          </Typography>
          <Typography
            variant="body1"
            color="text.secondary"
            sx={{ maxWidth: 800, mx: "auto", mb: 4 }}
          >
            Whether you're a first-time host or an experienced investor, our
            calculator provides the insights you need to maximize your Airbnb
            returns.
          </Typography>
          <Button
            variant="contained"
            size="large"
            onClick={() => navigate("/calculator")}
            sx={{
              textTransform: "none",
              px: 4,
              py: 1.5,
              borderRadius: 2,
            }}
          >
            Try Calculator Now
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default CalculatorPromo;
