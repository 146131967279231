/* eslint-disable react/prop-types */
import React, { useState } from "react";
import {
  PieChart,
  Pie,
  Cell,
  Sector,
  ResponsiveContainer,
  Tooltip,
} from "recharts";

import PropTypes from "prop-types";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Tooltip as MuiTooltip,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const MarketPieCard = ({
  data,
  keyName,
  hollowPie,
  colors,
  title,
  tooltip,
  initialActiveIndex,
  gap,
}) => {
  const iRadius = hollowPie ? 60 : 1;
  const oRadius = 80;

  const [activeIndex, setActiveIndex] = useState(initialActiveIndex);

  const CustomizedLabel = (props) => {
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      fill,
      payload: { count, name, percent },
    } = props;
    const RADIAN = Math.PI / 180;
    const radius = 25 + innerRadius + (outerRadius - innerRadius);
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    const dx = x > cx ? 30 : -30;
    const roundedPercent = (percent * 100).toFixed(1);

    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;

    return (
      <g>
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <text
          x={x}
          y={y}
          fill={fill}
          textAnchor={x > cx ? "start" : "end"}
          dominantBaseline="central"
        >
          <tspan
            x={x}
            dx={dx}
            y={y}
            dy={0}
          >{`${count} ${name}s (${roundedPercent}%)`}</tspan>
        </text>
      </g>
    );
  };

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="#333"
        >
          {payload.name} days
        </text>
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fill="#333"
        >{`${value} properties (${(payload.payload.percent * 100).toFixed(
          1
        )}%)`}</text>
      </g>
    );
  };

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  return (
    <Card sx={{ height: "350px", m: "15px" }} elevation={3}>
      <CardContent>
        <Box display="flex" justifyContent="space-between">
          <Typography color="textPrimary" gutterBottom>
            {title}
          </Typography>
          <MuiTooltip enterTouchDelay={100} title={tooltip}>
            <InfoOutlinedIcon fontSize="small" color="disabled" />
          </MuiTooltip>
        </Box>
        <Box display="flex" justifyContent="center">
          <ResponsiveContainer width="100%" height={260}>
            <PieChart cx="50%">
              {hollowPie ? (
                <Pie
                  activeIndex={activeIndex}
                  activeShape={renderActiveShape}
                  data={data}
                  cx="50%"
                  cy="50%"
                  innerRadius={iRadius}
                  outerRadius={oRadius}
                  fill="#8884d8"
                  dataKey={keyName}
                  onMouseEnter={onPieEnter}
                  startAngle={90} // customize angle so the pie chart starts from 0 degree instead of 90 degress
                  endAngle={-270}
                  paddingAngle={gap}
                >
                  {data.map((_entry, index) => (
                    <Cell key={index} fill={colors[index % colors.length]} />
                  ))}
                </Pie>
              ) : (
                <Pie
                  data={data}
                  cx="50%"
                  cy="50%"
                  innerRadius={iRadius}
                  outerRadius={oRadius}
                  fill="#8884d8"
                  dataKey={keyName}
                  onMouseEnter={onPieEnter}
                  paddingAngle={gap}
                  label={<CustomizedLabel />}
                  labelLine={false}
                  isAnimationActive={false}
                >
                  {data.map((_entry, index) => (
                    <Cell key={index} fill={colors[index % colors.length]} />
                  ))}
                </Pie>
              )}
            </PieChart>
          </ResponsiveContainer>
        </Box>
      </CardContent>
    </Card>
  );
};

export default MarketPieCard;
