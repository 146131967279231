import { createSelector } from "reselect";
import { DateTime } from "luxon";
import symbolMapping from "../constants/currency";
import {
  backward12MonthsEndMillis,
  backward12MonthsStartMillis,
  forward12MonthsEndMillis,
  forward12MonthsStartMillis,
} from "../util/dateutil";

export const selectAirbnbListingsQueryLocation = (state) =>
  state.airbnbListings.query.location;

export const selectQueryCurrency = (state) =>
  state.airbnbListings.query.currency;

export const selectNativeCurrency = (state) =>
  state.airbnbListings.nativeCurrency;

export const selectQuerySortOrder = (state) =>
  state.airbnbListings.query.sortOrder;

export const selectAtlasCurrencySymbol = createSelector(
  selectQueryCurrency,
  selectNativeCurrency,
  (queryCurrency, nativecurrency) =>
    symbolMapping[queryCurrency ?? nativecurrency]
);

export const selectDays = (state) => state.airbnbListings.aggregates.days;
export const selectForwardDays = createSelector(selectDays, (days) =>
  days.filter(
    (item) =>
      item.date >= forward12MonthsStartMillis &&
      item.date <= forward12MonthsEndMillis
  )
);

export const selectBackwardDays = createSelector(selectDays, (days) =>
  days.filter(
    (item) =>
      item.date >= backward12MonthsStartMillis &&
      item.date <= backward12MonthsEndMillis
  )
);

export const selectForwardMonths = createSelector(selectForwardDays, (days) =>
  days.reduce((acc, item) => {
    const date = DateTime.fromMillis(item.date, { zone: "utc" });

    // Get the year and month of the DateTime object
    const yearMonth = date.toFormat("MMMM yyyy");

    if (!acc[yearMonth]) {
      acc[yearMonth] = [];
    }
    acc[yearMonth].push(item);
    return acc;
  }, {})
);

export const selectAmenitiesInputFn = (state) =>
  state.airbnbListings.aggregates.topAmenities;

export const selectAmenities = createSelector(
  selectAmenitiesInputFn,
  (amenities) => {
    return Object.keys(amenities).map((key) => {
      return {
        name: key,
        count: amenities[key],
      };
    });
  }
);

export const selectMonths = (state) => state.airbnbListings.aggregates.months;

export const selectLast12Months = createSelector(selectMonths, (months) => {
  return months.slice(months.length - 12);
});

export const selectAggregates = (state) => state.airbnbListings.aggregates;

export const selectListings = (state) => state.airbnbListings.listings;

export const selectListingsDetails = (state) =>
  state.airbnbListings.listingsDetails;
