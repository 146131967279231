import Finance from "./finance";

const calculateReturn = ({
  propertyValue,
  monthlyRent,
  monthlyExpense,
  downPayment,
  interestRate,
}) => {
  const MONTHLY_PAYMENT = 1;
  const finance = new Finance();
  const mortgage = finance.AM(
    propertyValue - downPayment,
    interestRate,
    360,
    MONTHLY_PAYMENT
  );
  const netOperatingIncome = monthlyRent * 12 - monthlyExpense * 12;
  const cashFlow = netOperatingIncome - mortgage;
  const cashOnCash = (cashFlow / downPayment) * 100;
  const capitalizationRate = (netOperatingIncome / propertyValue) * 100;

  return {
    cashOnCash: cashOnCash.toFixed(2),
    capitalizationRate: capitalizationRate.toFixed(2),
  };
};

export default calculateReturn;
