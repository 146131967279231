/* eslint-disable react/prop-types */
import React from "react";

import PropTypes from "prop-types";
import {
  Box,
  Card,
  Tooltip,
  CardContent,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { Line, LineChart, ResponsiveContainer, YAxis } from "recharts";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { indexToMonth } from "../util/dataUtil";
import { useSelector } from "react-redux";

import { selectAtlasCurrencySymbol } from "../selectors/airbnbListingsSelectors";

const MarketSimpleLineCard = ({ data, type, color }) => {
  const symbol = useSelector(selectAtlasCurrencySymbol);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const values = data.map((item) => item.value);
  const min = Math.min(...values);
  const max = Math.max(...values);
  const minIdx = values.findIndex((value) => value === min);
  const maxIdx = values.findIndex((value) => value === max);

  const yAxisDomain = [min * 0.95, max * 1.05];

  const dataObj = data.map((item) => {
    return { value: item.value };
  });

  const months = data.map((item) => indexToMonth(item.date.getMonth()));

  const avg = Math.round(
    data.reduce(
      (partial, item) =>
        partial + (type === "OCCUPANCY" ? item.value * 100 : item.value),
      0
    ) / data.length
  );

  const CustomizedDot = ({ cx, cy, index }) => {
    // note this logic only shows 1 min/max dot if there are multiple
    if (index === maxIdx || index === minIdx) {
      return (
        <circle
          cx={cx}
          cy={cy}
          r="4"
          stroke="black"
          strokeWidth="1"
          fill={color}
        />
      );
    }

    return null;
  };

  const tooltipText = () => {
    switch (type) {
      case "ADR":
        return "The average nightly rate over the past 12 months.";
      case "OCCUPANCY":
        return "The percentage of nights booked out of total nights over the past 12 months.";
      case "REVENUE":
        return "Average monthly revenue over the past 12 months.";
      default:
        return null;
    }
  };

  const labelText = (month, value) => {
    switch (type) {
      case "ADR":
        return `${month}: ${symbol} ${value}`;
      case "OCCUPANCY":
        return `${month}: ${value}%`;
      case "REVENUE":
        return `${month}: ${symbol} ${value}`;
      default:
        return null;
    }
  };

  const titleText = () => {
    switch (type) {
      case "ADR":
        return isMobile ? "ADR" : "Average Daily Rate";
      case "OCCUPANCY":
        return "Occupancy";
      case "REVENUE":
        return "Revenue";
      default:
        return null;
    }
  };

  const CustomizedLabel = ({ x, y, value, index }) => {
    const month = months[index];

    const newValue = Math.round(type === "OCCUPANCY" ? value * 100 : value);
    // note we only mark the first occurence of min and max values on the curse in case there are more than one min/max
    if (index === minIdx) {
      // this text is SVG
      return (
        <text
          x={x}
          y={y}
          dy={isMobile ? 16 : 24}
          fontSize={12}
          textAnchor="middle"
        >
          {labelText(month, newValue)}
        </text>
      );
    }
    if (index === maxIdx) {
      // this text is SVG
      return (
        <text
          x={x}
          y={y}
          dy={isMobile ? -8 : -16}
          fontSize={12}
          textAnchor="middle"
        >
          {labelText(month, newValue)}
        </text>
      );
    }
    return null;
  };

  return (
    <Box width={isMobile ? "100%" : "33%"}>
      {isMobile ? (
        <Card
          sx={{
            height: "100px",
            border: "1px solid",
            borderColor: "#e0e0e0",
            px: 2,
          }}
        >
          <CardContent
            sx={{
              padding: "8px",
              height: "100px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={{ flexGrow: 1 }}
            >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                sx={{ minWidth: 0, mr: 1 }}
              >
                <Typography
                  color="textPrimary"
                  variant="body2"
                  sx={{ lineHeight: 1.2 }}
                >
                  {titleText()}
                </Typography>
                <Typography
                  variant="body2"
                  color="primary"
                  sx={{
                    mt: "2px",
                    lineHeight: 1.2,
                  }}
                >
                  {type === "OCCUPANCY" ? `${avg}%` : `${symbol}${avg}`}
                </Typography>
              </Box>
              <Box sx={{ flexGrow: 1, minWidth: 0, height: "84px" }}>
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart
                    margin={{
                      top: 20,
                      right: 50,
                      left: 50,
                      bottom: 20,
                    }}
                    data={dataObj}
                  >
                    <YAxis domain={yAxisDomain} hide />
                    <Line
                      type="linear"
                      dataKey="value"
                      dot={<CustomizedDot />}
                      stroke={color}
                      strokeWidth={2}
                      isAnimationActive={false}
                      label={<CustomizedLabel />}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </Box>
              <Tooltip title={tooltipText()}>
                <InfoOutlinedIcon
                  fontSize="small"
                  color="disabled"
                  sx={{ ml: 1 }}
                />
              </Tooltip>
            </Box>
          </CardContent>
        </Card>
      ) : (
        <Card
          sx={{
            height: "150px",
            maxHeight: "150px",
            border: "1px solid",
            borderColor: "#e0e0e0",
          }}
        >
          <CardContent sx={{ paddingBottom: "16px", height: "150px" }}>
            <Box display="flex" justifyContent="space-between">
              <Typography color="textPrimary" variant="body2" gutterBottom>
                {titleText()}
              </Typography>
              <Tooltip title={tooltipText()}>
                <InfoOutlinedIcon fontSize="small" color="disabled" />
              </Tooltip>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h5" color="primary">
                {type === "OCCUPANCY" ? `${avg}%` : `${symbol}${avg}`}
              </Typography>
              <ResponsiveContainer height={100}>
                <LineChart
                  margin={{
                    top: 30,
                    right: isMobile ? 4 : 40,
                    left: isMobile ? 4 : 40,
                    bottom: 25,
                  }}
                  data={dataObj}
                >
                  <YAxis domain={yAxisDomain} hide />

                  <Line
                    type="linear"
                    dataKey="value"
                    dot={<CustomizedDot />}
                    stroke={color}
                    strokeWidth={3}
                    isAnimationActive={false}
                    label={<CustomizedLabel />}
                  />
                </LineChart>
              </ResponsiveContainer>
            </Box>
          </CardContent>
        </Card>
      )}
    </Box>
  );
};

MarketSimpleLineCard.propTypes = {
  type: PropTypes.oneOf(["ADR", "OCCUPANCY", "REVENUE"]).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.instanceOf(Date),
      data: PropTypes.number,
    })
  ).isRequired,
  color: PropTypes.string.isRequired,
};

export default MarketSimpleLineCard;
