/* eslint-disable react/prop-types */
import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  Tooltip as MuiTooltip,
  CardContent,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { DISTINCT_COLORS } from "../util/color";
import MarketLineCard from "./MarketLineCard";
import { getMonthYearLabel } from "../util/dateutil";
import withErrorBoundary from "./error/withErrorBoundary";

const MarketOccupancyTimesSeriesCard = ({ data }) => {
  const lineNames = ["25 pct", "50 pct", "75 pct", "90 pct"];
  data.sort((left, right) => left - right);
  const occupancyData = data.map((monthly) => {
    return {
      date: getMonthYearLabel(monthly.date),
      ...Object.fromEntries(
        Object.entries(monthly.occupancyPercentiles).map(([k, v], _) => {
          return [`${parseInt(k)} pct`, v];
        })
      ),
    };
  });

  return (
    <MarketLineCard
      data={occupancyData}
      lineNames={lineNames}
      activeLines={[lineNames[1], lineNames[2]]}
      label="Occupancy"
      tooltip="Monthly occupancy breakdown showing 25th, 50th, 75th, and 90th percentiles."
    />
  );
};

export default withErrorBoundary(MarketOccupancyTimesSeriesCard);
