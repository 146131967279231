/* eslint-disable react/forbid-prop-types */

import PropTypes from "prop-types";

export const listingPropType = PropTypes.shape({
  listingId: PropTypes.number.isRequired,
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
  state: PropTypes.string.isRequired,
  zipCode: PropTypes.string.isRequired,
  revenue: PropTypes.number.isRequired,
  daysOccupied: PropTypes.number.isRequired,
  photoURL: PropTypes.string,
  bedrooms: PropTypes.number.isRequired,
  listingType: PropTypes.string,
  occupancyRate: PropTypes.number.isRequired,
  listingName: PropTypes.string.isRequired,
  countryCode: PropTypes.string.isRequired,
  guests: PropTypes.number.isRequired,
  beds: PropTypes.number.isRequired,
  baths: PropTypes.number.isRequired,
  numReviews: PropTypes.number.isRequired,
  minNights: PropTypes.number.isRequired,
  starRating: PropTypes.number.isRequired,
  averageDailyRate: PropTypes.number.isRequired,
  trailing: PropTypes.bool.isRequired,
});

export const aggregatesPropType = PropTypes.shape({
  months: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.number.isRequired,
      pricePercentiles: PropTypes.object.isRequired,
      occupancyPercentiles: PropTypes.object.isRequired,
      activeListings: PropTypes.object.isRequired,
      bookedListings: PropTypes.object.isRequired,
      nights: PropTypes.object.isRequired,
      revenue: PropTypes.object.isRequired,
      revenuePercentiles: PropTypes.object.isRequired,
    })
  ),
  availableDaysBreakdown: PropTypes.object,
  unavailableDaysBreakdown: PropTypes.object,
  listingTypeBreakdown: PropTypes.object,
  roomCapacityBreakdown: PropTypes.object,
  guestCapacityBreakdown: PropTypes.object,
  cancellationPolicyBreakdown: PropTypes.object,
  minNightsBreakdown: PropTypes.object,
});

export const marketSummaryPropType = PropTypes.shape({
  months: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.number.isRequired,
      price: PropTypes.number.isRequired,
      occupancy: PropTypes.number.isRequired,
      revenue: PropTypes.number.isRequired,
    })
  ),
});
