/* eslint-disable react/prop-types */
import React, { useState } from "react";

import {
  Box,
  Card,
  Tooltip as MuiTooltip,
  CardContent,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import {
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
} from "recharts";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { DISTINCT_COLORS } from "../util/color";
import { fullYearTo2DigitsYear, getMonthYearLabel } from "../util/dateutil";
import { selectAtlasCurrencySymbol } from "../selectors/airbnbListingsSelectors";
import { useSelector } from "react-redux";
import { abbreviateNumber } from "../util/jsutil";
import { useBreakpointValue } from "../util/hooks";

const MarketLineCard = ({
  data,
  lineNames,
  activeLines,
  label,
  tooltip,
  tiltXAxisTicker,
  yAxisShowCurrency,
  tooltipDecimals,
  dotRadius,
}) => {
  const dotSize =
    dotRadius ??
    useBreakpointValue(
      {
        xs: 0,
        sm: 1,
        lg: 2,
      },
      "up"
    );
  const currencySymbol = useSelector(selectAtlasCurrencySymbol);

  const [lineStatuses, setLineStatuses] = useState(() => {
    return lineNames.reduce((obj, key) => {
      return {
        ...obj,
        [key]: activeLines.includes(key),
      };
    }, {});
  });

  const changeLineStatuses = (event) => {
    // if not set, event could be set to null for permance optimization reasons by React <= v 16.0
    // see https://reactjs.org/docs/legacy-event-pooling.html
    event.persist();
    setLineStatuses((state) => {
      return { ...state, [event.target.name]: event.target.checked };
    });
  };

  return (
    <Card sx={{ height: "350px", m: "15px" }} elevation={3}>
      <CardContent sx={{ height: "350px" }}>
        <Box display="flex" justifyContent="space-between">
          <Typography color="textPrimary" gutterBottom>
            {label}
          </Typography>
          <MuiTooltip enterTouchDelay={100} title={tooltip}>
            <InfoOutlinedIcon fontSize="small" color="disabled" />
          </MuiTooltip>
        </Box>
        <ResponsiveContainer width="100%" height={290}>
          <LineChart data={data}>
            <CartesianGrid stroke="#eee" strokeDasharray="4" vertical={false} />
            <XAxis
              dataKey="date"
              angle={tiltXAxisTicker ? -45 : 0}
              textAnchor={tiltXAxisTicker ? "end" : "default"}
              tick={{ fontSize: tiltXAxisTicker ? 12 : 16 }}
              height={tiltXAxisTicker ? 80 : 50} // 30 is the default
              interval="preserveStart"
              tickFormatter={(value) => {
                return tiltXAxisTicker ? fullYearTo2DigitsYear(value) : value;
              }}
            />
            <YAxis
              tickFormatter={(value) => {
                return yAxisShowCurrency
                  ? abbreviateNumber(value, currencySymbol)
                  : value;
              }}
            />
            <Tooltip
              itemSorter={(item) => {
                return item.value * -1; // from high to low
              }}
              cursor={false}
              formatter={(value) =>
                yAxisShowCurrency
                  ? abbreviateNumber(
                      value,
                      currencySymbol,
                      tooltipDecimals != null ? tooltipDecimals : 0
                    )
                  : value
              }
            />
            {Object.entries(lineStatuses).map(([lineName, status], idx) => {
              if (status) {
                return (
                  <Line
                    key={idx}
                    type="linear"
                    dataKey={lineName}
                    stroke={DISTINCT_COLORS[idx]}
                    strokeWidth={2}
                    dot={{ r: dotSize }}
                    isAnimationActive={false}
                  />
                );
              }
              return null;
            })}
          </LineChart>
        </ResponsiveContainer>
        <Box display="flex" justifyContent="center" marginTop="-32px">
          {lineNames.map((lineName, idx) => {
            return (
              <Box key={idx} ml={1}>
                <CustomizedLegend
                  checked={lineStatuses[lineName]}
                  onChange={changeLineStatuses}
                  name={lineName}
                  color={DISTINCT_COLORS[idx]}
                />
              </Box>
            );
          })}
        </Box>
      </CardContent>
    </Card>
  );
};

const CustomizedLegend = ({ checked, onChange, name, color }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          sx={{
            ".MuiSvgIcon-root": {
              color: `${color}`,
            },
          }}
          checked={checked}
          onChange={onChange}
          name={name}
          size="small"
        />
      }
      label={
        <Typography
          sx={{
            color: `${color}`,
            fontSize: {
              xs: 12,
              md: 14,
              lg: 16,
            },
            textWrap: "nowrap",
          }}
        >
          {name}
        </Typography>
      }
    />
  );
};

export default MarketLineCard;
