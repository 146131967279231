import React, { createContext, useContext } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Grid,
  TextField,
  Autocomplete,
  Typography,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import NumberFormat from "react-number-format";
import CalculateIcon from "@mui/icons-material/Calculate";

const EditModeContext = createContext();

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#e0e0e0",
    },
    "&:hover fieldset": {
      borderColor: theme.palette.primary.main,
    },
  },
  "& .MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: "rgba(0, 0, 0, 0.87)",
    cursor: "default",
    userSelect: "none",
  },
}));

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#e0e0e0",
    },
    "&:hover fieldset": {
      borderColor: theme.palette.primary.main,
    },
  },
  "& .MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: "rgba(0, 0, 0, 0.87)",
    cursor: "default",
    userSelect: "none",
  },
}));

const EditButtons = () => {
  const { isEditMode, setIsEditMode, onCalculate } =
    useContext(EditModeContext);

  const handleCalculateClick = () => {
    setIsEditMode(false);
    onCalculate();
  };

  return (
    <Box sx={{ display: "flex", gap: 2 }}>
      <Button
        variant={isEditMode ? "outlined" : "contained"}
        onClick={() => setIsEditMode(!isEditMode)}
        size="small"
        sx={{ textTransform: "none" }}
      >
        {isEditMode ? "Cancel" : "Edit"}
      </Button>
      {isEditMode && (
        <Button
          variant="contained"
          color="primary"
          startIcon={<CalculateIcon />}
          onClick={handleCalculateClick}
          size="small"
          sx={{ textTransform: "none" }}
        >
          Calculate
        </Button>
      )}
    </Box>
  );
};

const PropertyDetails = ({
  propertyDetails,
  onInputChange,
  predictions,
  searchInput,
  handlePlaceSelect,
  handleAddressSearch,
  isLoaded,
}) => {
  const { isEditMode } = useContext(EditModeContext);

  return (
    <Box>
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={8}>
          <StyledAutocomplete
            size="small"
            freeSolo
            options={predictions}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.description
            }
            filterOptions={(x) => x}
            value={propertyDetails.address}
            onChange={(event, newValue) => {
              if (newValue && typeof newValue !== "string" && isEditMode) {
                handlePlaceSelect(newValue.place_id);
              }
            }}
            onInputChange={(event, newInputValue) => {
              if (isEditMode) {
                handleAddressSearch(newInputValue);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Address"
                variant="outlined"
                fullWidth
                placeholder="Enter property address"
                disabled={!isEditMode}
              />
            )}
            renderOption={(props, option) => (
              <li {...props}>
                <Typography variant="body2">{option.description}</Typography>
              </li>
            )}
            loading={!isLoaded}
            loadingText="Loading Google Places..."
            noOptionsText="No addresses found"
            disabled={!isEditMode}
          />
        </Grid>
        <Grid item xs={4}>
          <NumberFormat
            customInput={StyledTextField}
            thousandSeparator
            prefix="$"
            size="small"
            fullWidth
            label="Purchase Price"
            value={propertyDetails.purchasePrice}
            onValueChange={(values) => {
              if (isEditMode) {
                onInputChange("purchasePrice")({
                  target: { value: values.value },
                });
              }
            }}
            disabled={!isEditMode}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <StyledTextField
            size="small"
            fullWidth
            label="Bedrooms"
            type="number"
            value={propertyDetails.bedrooms}
            onChange={(e) => isEditMode && onInputChange("bedrooms")(e)}
            InputProps={{
              inputProps: { min: 0 },
            }}
            disabled={!isEditMode}
          />
        </Grid>
        <Grid item xs={4}>
          <StyledTextField
            size="small"
            fullWidth
            label="Bathrooms"
            type="number"
            value={propertyDetails.bathrooms}
            onChange={(e) => isEditMode && onInputChange("bathrooms")(e)}
            InputProps={{
              inputProps: { min: 0 },
            }}
            disabled={!isEditMode}
          />
        </Grid>
        <Grid item xs={4}>
          <StyledTextField
            size="small"
            fullWidth
            label="Guests"
            type="number"
            value={propertyDetails.guests}
            onChange={(e) => isEditMode && onInputChange("guests")(e)}
            InputProps={{
              inputProps: { min: 0 },
            }}
            disabled={!isEditMode}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const EditModeProvider = ({ children, value }) => (
  <EditModeContext.Provider value={value}>{children}</EditModeContext.Provider>
);

EditModeProvider.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.shape({
    isEditMode: PropTypes.bool.isRequired,
    setIsEditMode: PropTypes.func.isRequired,
    onCalculate: PropTypes.func.isRequired,
  }).isRequired,
};

PropertyDetails.EditButtons = EditButtons;
PropertyDetails.EditModeProvider = EditModeProvider;

PropertyDetails.propTypes = {
  propertyDetails: PropTypes.shape({
    address: PropTypes.string,
    bedrooms: PropTypes.string,
    bathrooms: PropTypes.string,
    guests: PropTypes.string,
    purchasePrice: PropTypes.string,
  }).isRequired,
  onInputChange: PropTypes.func.isRequired,
  predictions: PropTypes.array.isRequired,
  searchInput: PropTypes.string.isRequired,
  handlePlaceSelect: PropTypes.func.isRequired,
  handleAddressSearch: PropTypes.func.isRequired,
  isLoaded: PropTypes.bool.isRequired,
};

export default PropertyDetails;
