import { useRef } from "react";
import { useTheme, useMediaQuery } from "@mui/material";

export const useComponentWillMount = (cb) => {
  const willMount = useRef(true);

  if (willMount.current) cb();

  willMount.current = false;
};

export const useBreakpointValue = (values, direction) => {
  const theme = useTheme();

  // Ensure direction is either 'up' or 'down'
  if (direction !== "up" && direction !== "down") {
    throw Error("direction needs to be either up or down");
  }

  // Create a sorted array of breakpoints
  const breakpointArray = Object.keys(theme.breakpoints.values).sort(
    (a, b) => theme.breakpoints.values[a] - theme.breakpoints.values[b]
  );

  // Use individual useMediaQuery calls for each breakpoint
  const matches = breakpointArray.map((breakpoint) => ({
    breakpoint,
    matches: useMediaQuery(theme.breakpoints[direction](breakpoint)),
  }));

  if (direction === "up") {
    // For 'up', find the largest matching breakpoint
    for (let i = matches.length - 1; i >= 0; i--) {
      const { breakpoint, matches: isMatching } = matches[i];
      if (isMatching && values[breakpoint] !== undefined) {
        return values[breakpoint];
      }
    }
  } else {
    // For 'down', find the smallest matching breakpoint
    for (let { breakpoint, matches: isMatching } of matches) {
      if (isMatching && values[breakpoint] !== undefined) {
        return values[breakpoint];
      }
    }
  }

  // If no matching breakpoint is found, return the smallest defined breakpoint's value for 'up'
  // or the largest defined breakpoint's value for 'down'
  const fallbackArray =
    direction === "up" ? [...breakpointArray].reverse() : breakpointArray;
  for (let breakpoint of fallbackArray) {
    if (values[breakpoint] !== undefined) {
      return values[breakpoint];
    }
  }

  // If no value is defined at all, return undefined
  return undefined;
};
